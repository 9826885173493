import React, { useEffect, useState } from "react";
import Header1 from "../diffuseurs/Header1";
import jsPDF from "jspdf";
import BASE_URL from '../../key';
import { useLocation } from "react-router-dom";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../components/sidbareauteurdiff";


const Payments = () => {
  const [paiements, setPaiements] = useState([]);
  const [factureDetails, setFactureDetails] = useState(null); // Détails du paiement sélectionné
  const [isFactureModalOpen, setIsFactureModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const userRoles = ["AUTEUR", "DIFFUSEUR"]; 
  const location = useLocation();
  const [isAuteurOpen, setIsAuteurOpen] = useState(false);
  const [isDiffuseurOpen, setIsDiffuseurOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Récupérer les paiements de l'utilisateur connecté
  useEffect(() => {
    const fetchPaiements = async () => {
      try {
        const response = await fetch(`${BASE_URL}/paiements`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des paiements");
        }

        const data = await response.json();
        setPaiements(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPaiements();
  }, []);

  // Ouvrir le modal avec les détails du paiement sélectionné
  const openFactureModal = (paiement) => {
    console.log(paiement); // Debug : Vérifiez si `paiement.user` contient les données
    setFactureDetails(paiement); // Stocke les détails pour la modale
    setIsFactureModalOpen(true);
  };
  

  // Fermer le modal
  const closeFactureModal = () => {
    setIsFactureModalOpen(false);
    setFactureDetails(null);
  };

  // Générer un PDF
  const generatePDF = () => {
    if (!factureDetails) return;

    const doc = new jsPDF();

    // Titre principal
    doc.setFontSize(16);
    doc.setTextColor(40);
    doc.text("FACTURE D'ABONNEMENT", 14, 15);

    // Ajouter un espace entre "FACTURE D'ABONNEMENT" et "EDITIONS7"
    doc.text("", 14, 25); // Ligne vide pour créer l'espacement

    // Adresse d'EDITIONS7 et NFSS (Alignées à gauche)
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text("EDITIONS7", 14, 35);
    doc.text("NFSS", 14, 40); // Ajout de NFSS
    doc.text("79 PASSAGE DE LA MOUSSIERE", 14, 45);
    doc.text("69700 LOIRE SUR RHONE", 14, 50);
    doc.text("FRANCE", 14, 55);

    // Informations utilisateur (Alignées à droite)
    const xRight = 190; // Position horizontale pour aligner parfaitement à droite
    doc.setFontSize(12);
    doc.setTextColor(80);
    doc.text(
      `Utilisateur : ${factureDetails.user?.prenom || ""} ${factureDetails.user?.username || "Non disponible"}`,
      xRight, 35, { align: "right" }
    );
    doc.text(`Adresse : ${factureDetails.user?.adresse_facturation || "Non disponible"}`, xRight, 40, { align: "right" });
    doc.text(`Code Postal : ${factureDetails.user?.code_postal || "Non disponible"}`, xRight, 45, { align: "right" });
    doc.text(`Ville : ${factureDetails.user?.ville || "Non disponible"}`, xRight, 50, { align: "right" });
    doc.text(`Pays : ${factureDetails.user?.country || "Non disponible"}`, xRight, 55, { align: "right" });

    // Détails de la facture
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(`Facture N° : ${factureDetails.numero_paiement || "Non disponible"}`, 14, 65);
    doc.text(
      `Date : ${
        factureDetails.date_paiement
          ? new Date(factureDetails.date_paiement).toLocaleDateString()
          : "Non disponible"
      }`,
      14,
      70
    );

    // Contenu du tableau
    const tableColumn = [
      "Numéro",
      "Montant (€)",
      "Mode de Paiement",
      "Début Abonnement",
      "Fin Abonnement",
      "Description",
    ];
    const tableRows = [
      [
        factureDetails.numero_paiement,
        `${factureDetails.montant || 0} €`,
        factureDetails.mode_paiement || "Non disponible",
        factureDetails.date_debut_abonnement
          ? new Date(factureDetails.date_debut_abonnement).toLocaleDateString()
          : "Non disponible",
        factureDetails.date_fin_abonnement
          ? new Date(factureDetails.date_fin_abonnement).toLocaleDateString()
          : "Non disponible",
        factureDetails.description || "Non disponible",
      ],
    ];

    // Ajout du tableau au PDF
    doc.autoTable({
      startY: 80,
      head: [tableColumn],
      body: tableRows,
      theme: "striped",
      headStyles: { fillColor: [22, 160, 133] }, // Couleur verte pour l'en-tête
      styles: { halign: "center", fontSize: 10 }, // Alignement et taille des cellules
    });

    // Pied de page
    const finalY = doc.lastAutoTable.finalY || 90; // Position finale après le tableau
    doc.setFontSize(10);
    doc.setTextColor(120);
    doc.text(
      "Editions7, 79 passage de la moussiere RCS LYON 392 354 202",
      105,
      finalY + 10,
      { align: "center" }
    );

    // Sauvegarde du PDF
    doc.save(`Facture-${factureDetails.numero_paiement || "Facture"}.pdf`);
};





  useEffect(() => {
    const path = location.pathname;

    // Set dropdown open state and highlight selected item
    if (["/livres-auteurs", "/recherche-pro", "/devis","/ventes","stocks",].includes(path)) {
      setIsAuteurOpen(true);
      setSelectedItem(path);
    } else if (["/points", "/recherche-auteurs", "/mes-ventes-factures"].includes(path)) {
      setIsDiffuseurOpen(true);
      setSelectedItem(path);
    }
  }, [location.pathname]);


  return (
    <div className="bg-white min-h-screen">
      <Header1 />
      <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                     {/* Sidebar */}
                     <Sidebar userRoles={userRoles} />

         < div className="md:col-span-3">
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
        <h2 className="text-xl font-bold mb-4">Mes Paiements</h2>
        {loading ? (
          <p>Chargement...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : paiements.length > 0 ? (
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 p-2">Numéro</th>
                <th className="border border-gray-300 p-2">Montant</th>
                <th className="border border-gray-300 p-2">Date</th>
                <th className="border border-gray-300 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {paiements.map((paiement) => (
                <tr key={paiement.id}>
                  <td className="border border-gray-300 p-2">
                    {paiement.numero_paiement}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {paiement.montant} €
                  </td>
                  <td className="border border-gray-300 p-2">
                    {new Date(paiement.date_paiement).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-2">
                    <button
                      onClick={() => openFactureModal(paiement)}
                      className="px-4 py-2 bg-green-500 text-white rounded-lg"
                    >
                      Voir Facture
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Aucun paiement trouvé.</p>
        )}
      </div>
      </div>
      </div>
</div>
      {/* Modal de Facture */}
      {isFactureModalOpen && factureDetails && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-4/5">
            {/* Header */}
            <div className="flex justify-between items-start mb-6">
              <div>
              <p>
  <strong>Utilisateur :</strong>{" "}
  {factureDetails.user?.prenom
    ? `${factureDetails.user.prenom} ${factureDetails.user.username}`
    : "Non disponible"}
</p>

<p>
  <strong>Adresse :</strong>{" "}
  {factureDetails.user?.adresse_facturation
    ? `${factureDetails.user.adresse_facturation}`
    : "Non disponible"}
</p>

<p>
  <strong>Code Postal :</strong>{" "}
  {factureDetails.user?.code_postal
    ? `${factureDetails.user.code_postal}`
    : "Non disponible"}
</p>
<p>
  <strong>Ville :</strong>{" "}
  {factureDetails.user?.ville
    ? `${factureDetails.user.ville}`
    : "Non disponible"}
</p>
<p>
  <strong>Pays :</strong>{" "}
  {factureDetails.user?.country
    ? `${factureDetails.user.country}`
    : "Non disponible"}
</p>
{/* <p>
  <strong>Siret :</strong>{" "}
  {factureDetails.user?.siret
    ? `${factureDetails.user.siret}`
    : "Non disponible"}
</p> */}

              </div>
              <div className="text-right">
                <p>
                  <strong>EDITIONS7</strong>
                  <br />
                  79 PASSAGE DE LA MOUSSIERE
                  <br />
                  69700 LOIRE SUR RHONE
                  <br />
                  FRANCE
                </p>
              </div>
            </div>

            {/* Facture Details */}
            <div className="text-left mb-6">
              <p>
                <strong>Facture N° :</strong>{" "}
                {factureDetails.numero_paiement || "Non disponible"}
              </p>
              <p>
                <strong>Date :</strong>{" "}
                {factureDetails.date_paiement
                  ? new Date(factureDetails.date_paiement).toLocaleDateString()
                  : "Date non disponible"}
              </p>
            </div>

            {/* Tableau des Détails */}
            <table className="table-auto w-full border-collapse border border-gray-400 mt-4">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 p-2">Numéro</th>
                  <th className="border border-gray-300 p-2">Montant</th>
                  <th className="border border-gray-300 p-2">Mode de Paiement</th>
                  <th className="border border-gray-300 p-2">Début Abonnement</th>
                  <th className="border border-gray-300 p-2">Fin Abonnement</th>
                  <th className="border border-gray-300 p-2">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.numero_paiement}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.montant} €
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.mode_paiement}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.date_debut_abonnement
                      ? new Date(
                          factureDetails.date_debut_abonnement
                        ).toLocaleDateString()
                      : "Non disponible"}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.date_fin_abonnement
                      ? new Date(
                          factureDetails.date_fin_abonnement
                        ).toLocaleDateString()
                      : "Non disponible"}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.description || "Non disponible"}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Footer */}
            <div className="text-center mt-6 text-sm text-gray-700">
                Editions7, 79 passage de la moussiere RCS LYON 392 354 202
            </div>
            <div className="text-right mt-6">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
                onClick={closeFactureModal}
              >
                Fermer
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-lg"
                onClick={generatePDF}
              >
                Télécharger en PDF
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payments;

import React, { useState, useEffect } from "react";
import BASE_URL from "../key";
import Sidebar from "../components/sidebarAuteur";
import Header1 from "./diffuseurs/Header1";

const PaidDevisList = () => {
  const [devis, setDevis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch paid devis for the logged-in user
  const fetchDevis = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/devis-paye`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Erreur serveur : ${response.status}`);
      }

      const { success, data } = await response.json();

      if (success) {
        setDevis(data);
      } else {
        throw new Error("Échec de la récupération des devis.");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDevis();
  }, []);

  if (loading) {
    return <div className="text-gray-500">Chargement des devis...</div>;
  }

  if (error) {
    return (
      <div className="text-red-500">
        <p>Erreur : {error}</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header1 />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <Sidebar />
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <h1 className="text-2xl font-bold mb-6">Mes Commandes</h1>
              <table className="table-auto border-collapse border border-gray-300 w-full">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Identifiant</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Livre</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Quantité</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Prix (€)</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {devis.map((item) => (
                    <tr key={item.id} className="hover:bg-gray-50">
                      <td className="py-3 px-4 border-b">{item.identifiant_commande || "N/A"}</td>
                      <td className="py-3 px-4 border-b">{item.livre_title || "Non spécifié"}</td>
                      <td className="py-3 px-4 border-b">{item.quantite}</td>
                      <td className="py-3 px-4 border-b">{item.prix}€</td>
                      <td className="py-3 px-4 border-b">
                        {new Date(item.created_at).toLocaleDateString("fr-FR")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaidDevisList;


// import React, { useState, useEffect } from 'react';
// import { IoMdArrowDropdown } from 'react-icons/io';
// import { FaBell } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom';
// import logo from '../diffuseurs/logo_editions7_transparent.png';
// import chat from '../diffuseurs/chat.png';
// import BASE_URL from '../../key';

// const Header1 = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [userRole, setUserRole] = useState('');
//   const [isChatOpen, setIsChatOpen] = useState(false);
//   const [notifications, setNotifications] = useState([]);
//   const [isNotifOpen, setIsNotifOpen] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const userLoggedIn = localStorage.getItem('isLoggedIn');
//     const role = localStorage.getItem('userRole');
//     if (userLoggedIn) {
//       setIsLoggedIn(true);
//       setUserRole(role);
//       fetchNotifications(); 
//     }

//     const interval = setInterval(() => {
//       if (isLoggedIn) {
//         fetchNotifications(); 
//       }
//     }, 5 * 60 * 1000); 

//     return () => clearInterval(interval); 
//   }, [isLoggedIn]);

//   const fetchNotifications = async () => {
//     try {
//       // const response = await fetch('http://localhost:8000/api/notifications', {
//       const response = await fetch(`${BASE_URL}/notifications`, {

//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       });
//       const data = await response.json();

//       if (Array.isArray(data)) {
//         setNotifications(data);
//       } else {
//         console.error('Les notifications retournées ne sont pas un tableau.');
//         setNotifications([]);
//       }
//     } catch (error) {
//       console.error('Erreur lors de la récupération des notifications :', error);
//       setNotifications([]);
//     }
//   };

//   const markAsRead = async (id) => {
//     try {
//       // const response = await fetch(`http://localhost:8000/api/notifications/${id}`, {
//         const response = await fetch(`${BASE_URL}/notifications/${id}`, {

//         method: 'PUT',
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error('Erreur lors de la mise à jour de la notification.');
//       }

//       const updatedNotifications = notifications.map((notif) =>
//         notif.id === id ? { ...notif, read: true } : notif
//       );
//       setNotifications(updatedNotifications);

//       console.log('Notification marquée comme lue.');
//     } catch (error) {
//       console.error('Erreur lors de la mise à jour de la notification :', error.message);
//     }
//   };

//   const handleNotificationClick = async (notif) => {
//     await markAsRead(notif.id); 
//     if (userRole === 'DIFFUSEUR' && notif.livre_id) {
//       navigate(`/livres/${notif.livre_id}`); 
//     } 
//     else if (userRole === 'AUTEUR-DIFFUSEUR' && notif.livre_id) {
//       navigate(`/livre/${notif.livre_id}`); 
//     }
//     else if (userRole === 'AUTEUR') {
//       console.log('Notification affichée pour AUTEUR, sans redirection.');
//     } else {
//       console.warn('Aucun comportement défini pour ce rôle ou cette notification.');
//     }
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('isLoggedIn');
//     localStorage.removeItem('userRole');
//     setIsLoggedIn(false);
//     navigate('/login');
//   };

//   const toggleChatDropdown = () => {
//     setIsChatOpen(!isChatOpen);
//   };

//   const toggleNotifDropdown = () => {
//     setIsNotifOpen(!isNotifOpen);
//   };

//   const unreadNotifications = notifications.filter((notif) => !notif.read).length;

//   return (
//     <header className="bg-[#00ae14] p-4 flex justify-between items-center">
//       <div className="flex items-center">
//         <a href="/acceuil">
//           <img src={logo} alt="Logo Editions 7" className="w-32 md:w-48 lg:w-[24rem] h-auto" />
//         </a>
//       </div>
//       <div className="flex space-x-8 relative items-center">
//         {/* Bouton Notifications */}
//         <div className="relative">
//           <button
//             className="text-white text-2xl relative"
//             onClick={toggleNotifDropdown}
//             title="Voir les notifications"
//           >
//             <FaBell className={unreadNotifications > 0 ? 'text-red-500' : 'text-white'} />
//             {unreadNotifications > 0 && (
//               <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-1">
//                 {unreadNotifications}
//               </span>
//             )}
//           </button>

//           {isNotifOpen && (
//             <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg z-10">
//               <div className="px-4 py-2 border-b border-gray-200 flex justify-between items-center">
//                 <h3 className="font-bold text-lg text-gray-700">Notifications</h3>
//                 <button
//                   className="text-blue-500 text-sm hover:underline"
//                   onClick={() => setNotifications(notifications.map((n) => ({ ...n, read: true })))}
//                 >
//                   Tout marquer comme lu
//                 </button>
//               </div>
//               <ul className="py-2 max-h-64 overflow-y-auto">
//                 {notifications.length > 0 ? (
//                   notifications.map((notif) => (
//                     <li
//                       key={notif.id}
//                       className={`flex items-start px-4 py-3 border-b border-gray-100 cursor-pointer hover:bg-gray-50 ${
//                         notif.read ? 'bg-gray-100' : 'bg-white'
//                       }`}
//                       onClick={() => handleNotificationClick(notif)}
//                     >
//                       <div className="flex-shrink-0">
//                         <FaBell className={notif.read ? 'text-gray-400' : 'text-[#26d43a]'} />
//                       </div>
//                       <div className="ml-3">
//                         <p
//                           className={`text-sm ${
//                             notif.read ? 'text-gray-500' : 'text-gray-700 font-bold'
//                           }`}
//                         >
//                           {notif.message}
//                         </p>
//                         <span className="text-xs text-gray-400">
//                           {notif.created_at
//                             ? `${new Date(notif.created_at).toLocaleDateString()} - ${new Date(
//                                 notif.created_at
//                               ).toLocaleTimeString()}`
//                             : ''}
//                         </span>
//                       </div>
//                     </li>
//                   ))
//                 ) : (
//                   <li className="px-4 py-3 text-gray-500 text-center">Aucune notification</li>
//                 )}
//               </ul>
//             </div>
//           )}
//         </div>

//         {/* Bouton Chat */}
//         <div className="relative inline-block text-left">
//       {/* Bouton principal */}
//       <button
//         className="bg-[#26d43a] text-white font-bold py-1 px-6 rounded-md flex items-center d"
//         onClick={toggleChatDropdown}
//       >
//         <img src={chat} alt="Icon" className="w-5 h-5 mr-2" />
//         CHAT
//         <IoMdArrowDropdown className="ml-2" />
//       </button>

//       {/* Menu Dropdown */}
//       {isChatOpen && (
//         <div className="absolute mt-2 w-44 bg-white border border-gray-300 rounded-md shadow-lg z-10">
//           <ul className="text-gray-800 text-sm">
//             <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
//               <a href="/chat" className="block">Mes messages</a>
//             </li>
//             <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
//               <a href="/tous-les-membres" className="block">Tous les membres</a>
//             </li>

//             {/* Conditionnel pour AUTEUR */}
//             {userRole === 'AUTEUR' && (
//               <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
//                 <a href="/rechercher-diffuseurs" className="block">Diffuseurs</a>
//               </li>
//             )}

//             {/* Conditionnel pour DIFFUSEUR */}
//             {userRole === 'DIFFUSEUR' && (
//               <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
//                 <a href="/rechercher-auteurs" className="block">Auteurs</a>
//               </li>
//             )}

//             <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
//               <a
//                 href={userRole === 'DIFFUSEUR' ? "/compte-diffuseur" : "/mon-compte"}
//                 className="block"
//               >
//                 Mon Compte
//               </a>
//             </li>
//           </ul>
//         </div>
//       )}
//     </div>


//         {/* Bouton Connexion/Déconnexion */}
//         {isLoggedIn ? (
//           <button
//             className="bg-[#26d43a] text-white font-bold py-1 px-6 rounded-lg"
//             onClick={handleLogout}
//           >
//             Déconnexion
//           </button>
//         ) : (
//           <a
//             href="/login"
//             className="bg-[#26d43a] text-white font-bold py-1 px-6 rounded-lg"
//           >
//             Connexion
//           </a>
//         )}
//       </div>
//     </header>
//   );
// };

// export default Header1;




import React, { useState, useEffect } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaBell } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import logo from '../diffuseurs/logo_editions7_transparent.png';
import chat from '../diffuseurs/chat.png';
import BASE_URL from '../../key';
import { useMemo } from 'react';


const Header1 = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNotifOpen, setIsNotifOpen] = useState(false);
  const navigate = useNavigate();
  

  useEffect(() => {
    const userLoggedIn = localStorage.getItem('isLoggedIn');
    const role = localStorage.getItem('userRole');
    if (userLoggedIn) {
      setIsLoggedIn(true);
      setUserRole(role);
      fetchNotifications(); 
    }

    const interval = setInterval(() => {
      if (isLoggedIn) {
        fetchNotifications(); 
      }
    }, 5 * 60 * 1000); 

    return () => clearInterval(interval); 
  }, [isLoggedIn]);

  useEffect(() => {
    const checkLoginStatus = () => {
      const userLoggedIn = localStorage.getItem('isLoggedIn');
      const role = localStorage.getItem('userRole');
      const token = localStorage.getItem('token');
  
      setIsLoggedIn(!!userLoggedIn);
      setUserRole(role);
  
      if (token) {
        fetchUserData(token);
      }
    };
  
    // 🔥 Exécuter immédiatement pour détecter l'inscription
    checkLoginStatus();
  
    // 🔥 Écouter les changements de `localStorage`
    window.addEventListener('storage', checkLoginStatus);
    
    return () => window.removeEventListener('storage', checkLoginStatus);
  }, []);
  
  
  const fetchUserData = async (token) => {
    try {
      const response = await fetch(`${BASE_URL}/user`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (response.ok) {
        const user = await response.json();
        console.log('User data:', user);
        // Stocker les infos utilisateur si besoin
      } else {
        console.error('Erreur de récupération des infos utilisateur.');
      }
    } catch (error) {
      console.error('Erreur API:', error);
    }
  };
  

  

  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${BASE_URL}/notifications`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();

      if (Array.isArray(data)) {
        setNotifications(data);
      } else {
        console.error('Les notifications retournées ne sont pas un tableau.');
        setNotifications([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des notifications :', error);
      setNotifications([]);
    }
  };

  const markAsRead = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/notifications/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de la notification.');
      }

      const updatedNotifications = notifications.map((notif) =>
        notif.id === id ? { ...notif, read: true } : notif
      );
      setNotifications(updatedNotifications);

      console.log('Notification marquée comme lue.');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la notification :', error.message);
    }
  };

  const handleNotificationClick = async (notif) => {
    try {
      console.log("Notification reçue :", notif);
  
      // Marquer la notification comme lue
      await markAsRead(notif.id);
  
      // Redirection en fonction des propriétés disponibles dans la notification
      // if (notif.devis_id) {
      //   navigate(`/devis/${notif.devis_id}`);
      // } else if (notif.devi_id) {
      //   navigate(`/devi/${notif.devi_id}`);
      // } else if (notif.livres_id) {
      //   navigate(`/livres/${notif.livres_id}`);
      // } else if (notif.livre_id)  {
      //   navigate(`/livre/${notif.livre_id}`);
      // } else {
      //   console.warn("Aucune redirection définie pour cette notification.");
      // }
      if (notif.devis_id) {
        navigate(`/devis/${notif.devis_id}`);
    } else if (notif.devi_id) {
        navigate(`/devi/${notif.devi_id}`);
    } else if (notif.livres_id) {
        navigate(`/livres/${notif.livres_id}`);
    } else if (notif.livre_id) {
        // Vérification du rôle avant de naviguer vers "/livre/{notif.livre_id}"
        if (userRole === "DIFFUSEUR" || userRole === "AUTEUR-DIFFUSEUR") {
            navigate(`/livre/${notif.livre_id}`);
        } else {
            console.warn("Accès refusé : Ce rôle n'a pas la permission d'accéder aux livres.");
        }
    } else {
        console.warn("Aucune redirection définie pour cette notification.");
    }
    } catch (error) {
      console.error("Erreur lors du traitement de la notification :", error);
    }
  };
  
  

  // const handleNotificationClick = async (notif) => {
  //   console.log("Notification reçue :", notif); // Vérifiez les données de la notification
  //   await markAsRead(notif.id); // Marquez la notification comme lue
  
  //   if (notif.devis_id) {
  //     if (notif.type === "devis") {
  //       navigate(`/devis/${notif.devis_id}`); // Redirection vers `/devis/:id`
  //     } else if (notif.type === "devi") {
  //       navigate(`/devi/${notif.devis_id}`); // Redirection vers `/devi/:id`
  //     }
  //   } else if (notif.livre_id) {
  //     if (notif.type === "livres") {
  //       navigate(`/livres/${notif.livre_id}`); // Redirection vers `/livres/:id`
  //     } else if (notif.type === "livre") {
  //       navigate(`/livre/${notif.livre_id}`); // Redirection vers `/livre/:id`
  //     }
  //   } else {
  //     console.warn("Aucune redirection définie pour cette notification.");
  //   }
  // };
  
  

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userRole');
    setIsLoggedIn(false);
    navigate('/login');
  };

  const toggleChatDropdown = () => {
    setIsChatOpen(!isChatOpen);
  };

  const toggleNotifDropdown = () => {
    setIsNotifOpen(!isNotifOpen);
  };

  const unreadNotifications = notifications.filter((notif) => !notif.read).length;

  return (
    <header className="bg-[#00ae14] p-4 flex justify-between items-center">
      <div className="flex items-center">
        <a href="/acceuil">
          <img src={logo} alt="Logo Editions 7" className="w-32 md:w-48 lg:w-[24rem] h-auto" />
        </a>
      </div>
      <div className="flex space-x-8 relative items-center">
        {/* Bouton Notifications */}
        <div className="relative">
          <button
            className="text-white text-2xl relative"
            onClick={toggleNotifDropdown}
            title="Voir les notifications"
          >
            <FaBell className={unreadNotifications > 0 ? 'text-red-500' : 'text-white'} />
            {unreadNotifications > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-1">
                {unreadNotifications}
              </span>
            )}
          </button>

          {isNotifOpen && (
            <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg z-10">
              <div className="px-4 py-2 border-b border-gray-200 flex justify-between items-center">
                <h3 className="font-bold text-lg text-gray-700">Notifications</h3>
                <button
                  className="text-blue-500 text-sm hover:underline"
                  onClick={() => setNotifications(notifications.map((n) => ({ ...n, read: true })))}
                >
                  Tout marquer comme lu
                </button>
              </div>
              <ul className="py-2 max-h-64 overflow-y-auto">
                {notifications.length > 0 ? (
                  notifications.map((notif) => (
                    <li
                      key={notif.id}
                      className={`flex items-start px-4 py-3 border-b border-gray-100 cursor-pointer hover:bg-gray-50 ${
                        notif.read ? 'bg-gray-100' : 'bg-white'
                      }`}
                      onClick={() => handleNotificationClick(notif)}
                    >
                      <div className="flex-shrink-0">
                        <FaBell className={notif.read ? 'text-gray-400' : 'text-[#26d43a]'} />
                      </div>
                      <div className="ml-3">
                        <p
                          className={`text-sm ${
                            notif.read ? 'text-gray-500' : 'text-gray-700 font-bold'
                          }`}
                        >
                          {notif.message}
                        </p>
                        <span className="text-xs text-gray-400">
                          {notif.created_at
                            ? `${new Date(notif.created_at).toLocaleDateString()} - ${new Date(
                                notif.created_at
                              ).toLocaleTimeString()}`
                            : ''}
                        </span>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="px-4 py-3 text-gray-500 text-center">Aucune notification</li>
                )}
              </ul>
            </div>
          )}
        </div>

        {/* Bouton Chat */}
        <div className="relative inline-block text-left">
          <button
            className="bg-[#26d43a] text-white font-bold py-1 px-6 rounded-md flex items-center d"
            onClick={toggleChatDropdown}
          >
            <img src={chat} alt="Icon" className="w-5 h-5 mr-2" />
            CHAT
            <IoMdArrowDropdown className="ml-2" />
          </button>

          {isChatOpen && (
            <div className="absolute mt-2 w-44 bg-white border border-gray-300 rounded-md shadow-lg z-10">
              <ul className="text-gray-800 text-sm">
              <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
  <a 
    href={["AUTEUR", "AUTEUR-DIFFUSEUR"].includes(userRole) ? "/chat" : "/auteur"} 
    className="block"
  >
    {["AUTEUR", "AUTEUR-DIFFUSEUR"].includes(userRole) ? "Mes messages" : "messages"}
  </a>
</li>

                <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
  <a 
    href={["AUTEUR", "DIFFUSEUR", "AUTEUR-DIFFUSEUR"].includes(userRole) ? "/membres" : "/auteurs"} 
    className="block"
  >
    {["AUTEUR", "DIFFUSEUR", "AUTEUR-DIFFUSEUR"].includes(userRole) ? "Tous les membres" : "Tous les auteurs"}
  </a>
</li>


                {/* {userRole === 'AUTEUR' && (
                  <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
                    <a href="/rechercher-diffuseurs" className="block">Diffuseurs</a>
                  </li>
                )}
                {userRole === 'DIFFUSEUR' && (
                  <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
                    <a href="/rechercher-auteurs" className="block">Auteurs</a>
                  </li>
                )} */}
                {/* <li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
                  <a
                    href={userRole === 'DIFFUSEUR' ? "/compte-diffuseur" : "/mon-compte"}
                    className="block"
                  >
                    Mon Compte
                  </a>
                </li> */}

<li className="hover:bg-gray-100 cursor-pointer px-4 py-2">
  <a
    href={
      userRole === 'AUTEUR' 
        ? "/mon-compte" 
        : userRole === 'DIFFUSEUR' 
        ? "/compte-diffuseur" 
        : userRole === 'AUTEUR-DIFFUSEUR' 
        ? "/compte-auteur-diffuseur" 
        : "/compte-autres"
    }
    className="block"
  >
    Mon Compte
  </a>
</li>

              </ul>
            </div>
          )}
        </div>

        {/* Bouton Connexion/Déconnexion */}
        {isLoggedIn ? (
          <button
            className="bg-[#26d43a] text-white font-bold py-1 px-6 rounded-lg"
            onClick={handleLogout}
          >
            Déconnexion
          </button>
        ) : (
          <a
            href="/login"
            className="bg-[#26d43a] text-white font-bold py-1 px-6 rounded-lg"
          >
            Connexion
          </a>
        )}
      </div>
    </header>
  );
};

export default Header1;

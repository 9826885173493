import React, { useState, useEffect } from "react";
import BASE_URL from "../../key";
import Header1 from "../diffuseurs/Header1";
import SidebarAutre from "../../components/sidebarAutre"; 


const UsersLists = () => {
  const [users, setUsers] = useState([]); // Liste des utilisateurs
  const [filteredUsers, setFilteredUsers] = useState([]); // Utilisateurs filtrés pour la recherche
  const [error, setError] = useState(null); // Gérer les erreurs
  const [selectedUser, setSelectedUser] = useState(null); // Utilisateur sélectionné pour le message
  const [message, setMessage] = useState(""); // Message à envoyer
  const [showModal, setShowModal] = useState(false); // État du modal
  const [searchTerm, setSearchTerm] = useState(""); // Mot-clé de recherche
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const [usersPerPage] = useState(8); // Nombre d'utilisateurs par page
  const userRoles = ["AUTEUR", "DIFFUSEUR"]; 


  // Récupérer tous les utilisateurs
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/auteurs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erreur serveur : ${response.status}`);
      }

      const result = await response.json();

      // Vérifier si "data" est un tableau valide
      if (result.success && Array.isArray(result.data)) {
        setUsers(result.data);
        setFilteredUsers(result.data); // Initialiser les utilisateurs filtrés
      } else {
        setUsers([]);
        setFilteredUsers([]);
        console.error("Les données retournées ne sont pas valides :", result);
      }
    } catch (err) {
      console.error("Erreur lors de la récupération des utilisateurs :", err);
      setError("Impossible de charger les utilisateurs.");
    }
  };

  const [currentUserRole, setCurrentUserRole] = useState(null);

  const fetchAuthenticatedUser = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.ok) {
        const data = await response.json();
        setCurrentUserRole(data.role); // Assurez-vous que `data.role` retourne "DIFFUSEUR" ou "AUTEUR"
      } else {
        console.error("Erreur lors de la récupération des données utilisateur.");
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
    }
  };

  useEffect(() => {
    fetchAuthenticatedUser();
  }, []);

  useEffect(() => {
    fetchUsers(); // Charger les utilisateurs au montage du composant
  }, []);

  // Gérer la recherche
  // Gérer la recherche
const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = users.filter((user) => {
      const prenom = user?.prenom ? user.prenom.toLowerCase() : "";
      const username = user?.username ? user.username.toLowerCase() : "";
      const role = user?.role ? user.role.toLowerCase() : "";
  
      return (
        prenom.includes(term) || username.includes(term) || role.includes(term)
      );
    });
    setFilteredUsers(filtered);
    setCurrentPage(1); // Revenir à la première page
  };
  

  // Gérer la pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Gérer l'ouverture du modal
  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  // Gérer la fermeture du modal
  const handleCloseModal = () => {
    setSelectedUser(null);
    setMessage("");
    setShowModal(false);
  };

  // Gérer l'envoi du message
  const handleSendMessage = async () => {
    if (!message.trim()) {
      alert("Le message ne peut pas être vide.");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/messages/send`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          receiver_id: selectedUser.id,
          content: message,
        }),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de l'envoi du message.");
      }

      alert("Message envoyé avec succès !");
      handleCloseModal();
    } catch (error) {
      console.error("Erreur lors de l'envoi du message :", error);
      alert("Une erreur s'est produite lors de l'envoi du message.");
    }
  };

  return (
    <div className="bg-white min-h-screen">
    {/* Header */}
    <Header1 />

    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
      
   
    <SidebarAutre />
  
          <div className="md:col-span-3">

        <h1 className="text-2xl font-bold mb-4">Tous les auteurs</h1>

        {/* Barre de recherche */}
        <div className="mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Rechercher par prénom, nom d'utilisateur ou rôle..."
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Liste des utilisateurs */}
        {Array.isArray(currentUsers) && currentUsers.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {currentUsers.map((user) => {
              const prenom = user?.prenom || "Non spécifié";
              const username = user?.username || "Non spécifié";
              const role = user?.role || "Inconnu";
              const photoProfil = user?.photo_profil;

              return (
                <div
                  key={user.id}
                  className="p-4 border rounded-md shadow hover:shadow-lg transition-shadow"
                >
                  <div className="flex items-center space-x-4">
                    {photoProfil ? (
                      <img
                        src={`https://editions7.com/admin/public/storage/${photoProfil}`}
                        alt={`${prenom} ${username}`}
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    ) : (
                      <div className="w-16 h-16 bg-gray-400 rounded-full flex items-center justify-center text-white font-bold">
                        {prenom[0]?.toUpperCase() || "?"}
                        {username[0]?.toUpperCase() || "?"}
                      </div>
                    )}

                    <div>
                      <h2 className="text-lg font-semibold">
                        {prenom} {username}
                      </h2>
                      <p className="text-sm text-gray-600">Rôle : {role}</p>
                    </div>
                  </div>

                  {/* Bouton Contacter */}
                  <button
                    onClick={() => handleOpenModal(user)}
                    className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors w-full"
                  >
                    Contacter
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-gray-500 text-center">Aucun utilisateur trouvé.</p>
        )}

        {/* Pagination */}
        {filteredUsers.length > usersPerPage && (
          <div className="flex justify-center mt-4 space-x-2">
            {Array.from({
              length: Math.ceil(filteredUsers.length / usersPerPage),
            }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`px-4 py-2 rounded-md ${
                  currentPage === index + 1
                    ? "bg-green-500 text-white"
                    : "bg-gray-200 text-gray-800"
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}

        {/* Modal pour envoyer un message */}
        {showModal && selectedUser && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
              <h2 className="text-xl font-bold mb-4">
                Envoyer un message à {selectedUser.prenom} {selectedUser.username}
              </h2>

              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Écrivez votre message ici..."
                className="w-full p-2 border border-gray-300 rounded-md mb-4"
                rows="4"
              ></textarea>

              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleCloseModal}
                  className="px-4 py-2 bg-gray-400 text-white rounded-md"
                >
                  Annuler
                </button>
                <button
                  onClick={handleSendMessage}
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                >
                  Envoyer
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
    </div>
    </div>
  );
};

export default UsersLists;

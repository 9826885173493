import React, { useState, useEffect } from "react";
import BASE_URL from "../key";
import Sidebar from "../components/sidebarAuteur";
import Header1 from "./diffuseurs/Header1";
import jsPDF from "jspdf";
import "jspdf-autotable";

const FacturesTable = () => {
  const [factures, setFactures] = useState([]);
  const [selectedFacture, setSelectedFacture] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch factures
  const fetchFactures = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/factures-impression`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Erreur serveur : ${response.status}`);
      }

      const { success, data } = await response.json();

      if (success) {
        setFactures(data);
      } else {
        throw new Error("Échec de la récupération des factures.");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Open modal with facture details
  const openModal = (facture) => {
    setSelectedFacture(facture);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setSelectedFacture(null);
    setIsModalOpen(false);
  };

  // Generate PDF for the selected facture
  const generatePDF = () => {
    if (!selectedFacture) return;

    const doc = new jsPDF();

    // Title (Facture N°)
    doc.setFontSize(16);
    doc.setTextColor(40);
    doc.text(`Facture N° : ${selectedFacture.id || "Non disponible"}`, 14, 15);

    // Ajouter un espace entre "Facture N°" et "EDITIONS7"
    doc.text("", 14, 25); // Ligne vide pour l'espacement

    // Édition7 Address (Left-Aligned)
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text("EDITIONS7", 14, 35);
    doc.text("79 PASSAGE DE LA MOUSSIERE", 14, 40);
    doc.text("69700 LOIRE SUR RHONE", 14, 45);

    // User Details (Right-Aligned)
    const xRight = 190;
    doc.setFontSize(12);
    doc.setTextColor(80);
    doc.text(
      `Utilisateur : ${selectedFacture.user_username || ""} ${selectedFacture.user_prenom || ""}`,
      xRight, 35, { align: "right" }
    );
    doc.text(
      `Adresse : ${selectedFacture.user_adresse_facturation || "Non spécifié"}`,
      xRight, 40, { align: "right" }
    );
    doc.text(
      `Code Postal : ${selectedFacture.user_code_postal || "Non spécifié"}`,
      xRight, 45, { align: "right" }
    );
    doc.text(`Ville : ${selectedFacture.user_ville || "Non spécifié"}`, xRight, 50, { align: "right" });
    doc.text(`Pays : ${selectedFacture.user_country || "Non spécifié"}`, xRight, 55, { align: "right" });
    doc.text(`Siret : ${selectedFacture.user_siret || "Non spécifié"}`, xRight, 60, { align: "right" });

    // Facture Details
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(
      `Date : ${
        selectedFacture.created_at
          ? new Date(selectedFacture.created_at).toLocaleDateString("fr-FR")
          : "Date non disponible"
      }`,
      14, 70
    );

    // Invoice Table
    const tableColumn = ["Nom du Livre", "Quantité", "Prix (€)", "Statut"];
    const tableRows = [
      [
        selectedFacture.livre_title || "Non spécifié",
        selectedFacture.quantite || "0",
        `${selectedFacture.amount} €`,
        selectedFacture.status,
      ],
    ];

    doc.autoTable({
      startY: 80,
      head: [tableColumn],
      body: tableRows,
      theme: "striped",
      headStyles: { fillColor: [22, 160, 133] },
      styles: { halign: "center", fontSize: 10 },
    });

    // Footer
    const finalY = doc.lastAutoTable.finalY || 90;
    doc.setFontSize(10);
    doc.setTextColor(120);
    doc.text(
      "Editions7, 79 passage de la moussiere RCS LYON 392 354 202",
      105, finalY + 10, { align: "center" }
    );

    // Save the PDF
    doc.save(`Facture-${selectedFacture.id}.pdf`);
};

  
  

  useEffect(() => {
    fetchFactures();
  }, []);

  if (loading) {
    return <div className="text-gray-500">Chargement des factures...</div>;
  }

  if (error) {
    return (
      <div className="text-red-500">
        <p>Erreur : {error}</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header1 />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <Sidebar />
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <h1 className="text-2xl font-bold mb-6">Factures d'impression</h1>
              <table className="table-auto border-collapse border border-gray-300 w-full">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Nom du Livre</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Quantité</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Prix (€)</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Statut</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Date de Paiement</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {factures.map((facture) => (
                    <tr key={facture.id} className="hover:bg-gray-50">
                      <td className="py-3 px-4 border-b">{facture.livre_title || "Non spécifié"}</td>
                      <td className="py-3 px-4 border-b">{facture.quantite}</td>
                      <td className="py-3 px-4 border-b">{facture.amount}€</td>
                      <td className="py-3 px-4 border-b">
                        <span
                          className={`px-2 py-1 rounded ${
                            facture.status === "succeeded"
                              ? "bg-green-100 text-green-700"
                              : "bg-red-100 text-red-700"
                          }`}
                        >
                          {facture.status}
                        </span>
                      </td>
                      <td className="py-3 px-4 border-b">
                        {new Date(facture.created_at).toLocaleDateString("fr-FR")}
                      </td>
                      <td className="py-3 px-4 border-b">
                        <button
                          className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                          onClick={() => openModal(facture)}
                        >
                          Voir et Télécharger
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && selectedFacture && (
  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
  <div className="bg-white p-8 rounded-lg shadow-lg w-4/5">
      {/* Header */}
      <div className="flex justify-between items-start mb-6">
          <div>
          <p className="mb-2">
          <strong>Utilisateur :</strong> {selectedFacture.user_username || "Non spécifié"}{" "}
          {selectedFacture.user_prenom || ""}
        </p>
        <p className="mb-2">
          <strong>Adresse :</strong> {selectedFacture.user_adresse_facturation || "Non spécifié"}{" "}
        </p>
        <p className="mb-2">
          <strong>Code Postal :</strong> {selectedFacture.user_code_postal || "Non spécifié"}{" "}
         
        </p>
        <p className="mb-2">
          <strong>Ville :</strong> {selectedFacture.user_ville || "Non spécifié"}{" "}
       
        </p>
        <p className="mb-2">
          <strong>Pays :</strong> {selectedFacture.user_country || "Non spécifié"}{" "}
        
        </p>
        <p className="mb-2">
          <strong>Siret :</strong> {selectedFacture.user_siret || "Non spécifié"}{" "}
     
        </p>
              {/* <p>
                  <strong>Email :</strong> {selectedFacture.diffuseur?.email || "Non disponible"}
              </p> */}
          </div>
          <div className="text-right">
              <p>
                  <strong>EDITIONS7</strong><br />
                  79 PASSAGE DE LA MOUSSIERE<br />
                  69700 LOIRE SUR RHONE<br />
                  FRANCE
                 
              </p>
          </div>
      </div>

      {/* Facture Details */}
      <div className="text-left mb-6">
          {/* <h2 className="text-xl font-bold">Facture</h2> */}
          <p className="mb-2">
          <strong>Facture N° :</strong> {selectedFacture.id || "Non disponible"}
        </p>
         
      </div>
      <div className="text-left mb-6">
          
      <p>
          <strong>Date :</strong>{" "}
          {selectedFacture.created_at
            ? new Date(selectedFacture.created_at).toLocaleDateString()
            : "Date non disponible"}
        </p>
      </div>

      {/* Facture Table */}
      <table className="table-auto border-collapse border border-gray-300 w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Nom du Livre</th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Quantité</th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Prix (€)</th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Statut</th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700 border-b">Date de Paiement</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-3 px-4 border-b">{selectedFacture.livre_title || "Non spécifié"}</td>
            <td className="py-3 px-4 border-b">{selectedFacture.quantite}</td>
            <td className="py-3 px-4 border-b">{selectedFacture.amount}€</td>
            <td className="py-3 px-4 border-b">{selectedFacture.status}</td>
            <td className="py-3 px-4 border-b">
              {new Date(selectedFacture.created_at).toLocaleDateString("fr-FR")}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Footer */}
      <div className="text-center mt-6 text-sm text-gray-700">
        Editions7, 79 passage de la moussiere RCS LYON 392 354 202
      </div>

      {/* Modal Actions */}
      <div className="mt-6 flex justify-end">
        <button
          className="px-4 py-2 bg-gray-400 text-white rounded mr-2"
          onClick={closeModal}
        >
          Fermer
        </button>
        <button
          className="px-4 py-2 bg-green-500 text-white rounded"
          onClick={generatePDF}
        >
          Télécharger PDF
        </button>
      </div>
    </div>
  </div>
)}


    </div>
  );
};

export default FacturesTable;

import React, { useState, useEffect } from 'react';
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import Header1 from '../diffuseurs/Header1';
import BASE_URL from '../../key';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/sidbareauteurdiff';
const Stocks = () => {
  const [username, setUsername] = useState(''); 
  const [stockData, setStockData] = useState([]); 
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuteurOpen, setIsAuteurOpen] = useState(false);
  const [isDiffuseurOpen, setIsDiffuseurOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const userRoles = ["AUTEUR", "DIFFUSEUR"]; 
  const [myStockData, setMyStockData] = useState([]); // Données de "Mon Stock"
  const [currentPageStocks, setCurrentPageStocks] = useState(1); // Page actuelle pour le premier tableau
  const [currentPageMyStock, setCurrentPageMyStock] = useState(1); // Page actuelle pour le deuxième tableau
  const itemsPerPage = 5; // Nombre d'éléments par page pour les deux tableaux
  const [showModal, setShowModal] = useState(false); // Contrôle du modal
  const [selectedBook, setSelectedBook] = useState(null); // Livre sélectionné pour la modification
  const [stockImprime, setStockImprime] = useState(''); // Stock Imprimé
  const [stockAuteur, setStockAuteur] = useState(''); // Stock Auteur

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        // Récupération des stocks principaux
        const stockResponse = await fetch(`${BASE_URL}/getStocks`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (stockResponse.ok) {
          const stockData = await stockResponse.json();
          setStockData(stockData);
        }

        // Récupération des livres pour "Mon Stock"
        const myStockResponse = await fetch(`${BASE_URL}/livres`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (myStockResponse.ok) {
          const myStockData = await myStockResponse.json();
          setMyStockData(myStockData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchStocks();
  }, [navigate]);

  // Pagination pour le tableau "Stocks des Livres Envoyés"
  const startIndexStocks = (currentPageStocks - 1) * itemsPerPage;
  const currentDataStocks = stockData.slice(startIndexStocks, startIndexStocks + itemsPerPage);
  const totalPagesStocks = Math.ceil(stockData.length / itemsPerPage);

  // Pagination pour le tableau "Mon Stock"
  const startIndexMyStock = (currentPageMyStock - 1) * itemsPerPage;
  const currentDataMyStock = myStockData.slice(startIndexMyStock, startIndexMyStock + itemsPerPage);
  const totalPagesMyStock = Math.ceil(myStockData.length / itemsPerPage);

  // Gérer le changement de page pour chaque tableau
  const handlePageChangeStocks = (page) => {
    setCurrentPageStocks(page);
  };

  const handlePageChangeMyStock = (page) => {
    setCurrentPageMyStock(page);
  };

  

  // Afficher le modal pour modifier un livre
  const handleModify = (book) => {
    setSelectedBook(book);
    setStockImprime(book.stock); // Pré-remplir le champ Stock Imprimé
    setStockAuteur(book.stock_auteur); // Pré-remplir le champ Stock Auteur
    setShowModal(true);
  };

  const fetchStocks = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
  
      // Récupérer les données pour "Mon Stock"
      const myStockResponse = await fetch(`${BASE_URL}/livres`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (myStockResponse.ok) {
        const myStockData = await myStockResponse.json();
        setMyStockData(myStockData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleUpdateStock = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/livres/${selectedBook.id}/update-stock`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stock: stockImprime,
          stock_auteur: stockAuteur,
        }),
      });
  
      if (response.ok) {
        // Fermer le modal après mise à jour
        setShowModal(false);
  
        // Actualiser les données pour "Mon Stock"
        fetchStocks();
      } else {
        console.error('Failed to update stock:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating stock:', error);
    }
  };
  
  return (
    <div className="bg-white min-h-screen">
      <Header1 />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <Sidebar userRoles={userRoles} />
            
       <div className="md:col-span-3">
            {/* Tableau 1: Stocks des Livres Envoyés */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <h3 className="text-md font-bold mb-4">Stocks des Livres Envoyés</h3>
              <div className="overflow-x-auto shadow-lg sm:rounded-lg">
                <table className="min-w-full text-sm text-left text-gray-600">
                  <thead className="bg-gray-200 text-gray-800">
                    <tr>
                      <th className="px-6 py-3 text-center">Titre du Livre</th>
                      <th className="px-6 py-3 text-center">Stock Imprimé</th>
                      <th className="px-6 py-3 text-center">Stock Editions7</th>
                      <th className="px-6 py-3 text-center">Stock Envoyé</th>
                      <th className="px-6 py-3 text-center">Stock Auteur</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentDataStocks.length > 0 ? (
                      currentDataStocks.map((stock, index) => (
                        <tr key={index} className="border-b hover:bg-gray-100">
                          <td className="px-6 py-4 text-center">{stock.livre}</td>
                          <td className="px-6 py-4 text-center">{stock.stock_imprime}</td>
                          <td className="px-6 py-4 text-center">{stock.stock_editions7}</td>
                          <td className="px-6 py-4 text-center">{stock.stock_envoye}</td>
                          <td className="px-6 py-4 text-center">{stock.stock_auteur}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center py-4 text-gray-500">
                          Chargement ou aucun stock disponible
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination pour le premier tableau */}
              <div className="flex justify-center mt-4">
                {[...Array(totalPagesStocks)].map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChangeStocks(index + 1)}
                    className={`px-4 py-2 mx-1 rounded-md ${
                      currentPageStocks === index + 1
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>

            {/* Tableau 2: Mon Stock */}
            <div className="bg-white shadow-md rounded-lg p-6 border border-gray-400">
              <h3 className="text-md font-bold mb-4">Mon Stock</h3>
              <div className="overflow-x-auto shadow-lg sm:rounded-lg">
                <table className="min-w-full text-sm text-left text-gray-600">
                  <thead className="bg-gray-200 text-gray-800">
                    <tr>
                      <th className="px-6 py-3 text-center">Nom Livre</th>
                      <th className="px-6 py-3 text-center">Stock Imprimé</th>
                      <th className="px-6 py-3 text-center">Stock Auteur</th>
                      <th className="px-6 py-3 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentDataMyStock.length > 0 ? (
                      currentDataMyStock.map((stock, index) => (
                        <tr key={index} className="border-b hover:bg-gray-100">
                          <td className="px-6 py-4 text-center">{stock.title}</td>
                          <td className="px-6 py-4 text-center">{stock.stock}</td>
                          <td className="px-6 py-4 text-center">{stock.stock_auteur}</td>
                          <td className="px-6 py-4 text-center">
                          <button
                              onClick={() => handleModify(stock)}
                              className="px-4 py-2 bg-green-500 text-white rounded-md hover:g-green-600"
                            >
                              Modifier
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center py-4 text-gray-500">
                          Aucun livre disponible
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination pour le deuxième tableau */}
              <div className="flex justify-center mt-4">
                {[...Array(totalPagesMyStock)].map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChangeMyStock(index + 1)}
                    className={`px-4 py-2 mx-1 rounded-md ${
                      currentPageMyStock === index + 1
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

       {/* Modal */}
       {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white w-1/3 p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Modifier le Stock</h2>
            <form onSubmit={handleUpdateStock}>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Stock Imprimé</label>
                <input
                  type="number"
                  value={stockImprime}
                  onChange={(e) => setStockImprime(e.target.value)}
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Stock Auteur</label>
                <input
                  type="number"
                  value={stockAuteur}
                  onChange={(e) => setStockAuteur(e.target.value)}
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 mr-2"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  Modifier
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Stocks;

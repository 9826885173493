import { IoMdArrowDropright } from 'react-icons/io';
import Header1 from '../diffuseurs/Header1';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../../key';
import Sidebar from '../../components/sidebarAutre';

const CompteA = () => {
  const [username, setUsername] = useState(''); // Store username dynamically
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     try {
  //       const token = localStorage.getItem('token');
  //       if (!token) {
  //         navigate('/login'); 
  //         return;
  //       }

  //       const response = await fetch('http://localhost:8000/api/user', {
  //         // const response = await fetch('https://editions7.com/admin/public/api/user', {

  //         method: 'GET',
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //         },
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         setUsername(data.username);
  //         setUserRole(data.role); 
  //         setIsLoggedIn(true);
  //       } else {
  //         navigate('/login'); 
  //       }
  //     } catch (error) {
  //       console.error('Error fetching user details:', error);
  //       navigate('/login');
  //     }
  //   };

  //   fetchUserDetails();
  // }, [navigate]);
useEffect(() => {
  const fetchUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found. Redirecting to login.');
      navigate('/login');
      return;
    }

    try {
      // const response = await fetch('http://localhost:8000/api/user', {
       const response = await fetch(`${BASE_URL}/user`, {

        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('User data:', data);
        setUsername(data.username);
        setUserRole(data.role);
        setIsLoggedIn(true);
      } else {
        console.error('API Error Status:', response.status);
        navigate('/login');
      }
    } catch (error) {
      console.error('Network error:', error);
      navigate('/login');
    }
  };

  fetchUserDetails();
}, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
    navigate('/login');
  };

  return (
    <div className="bg-white min-h-screen">
      <Header1 />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <p className="text-lg mb-4">
                Bonjour <strong>{username || 'Utilisateur'}</strong> (vous n'êtes pas{' '}
                <strong>{username || 'Utilisateur'}</strong>?{' '}
                <span onClick={handleLogout} className="text-blue-600 cursor-pointer">
                  Déconnexion
                </span>
                )
              </p>
              <p className="text-sm mb-4">
                À partir du tableau de bord de votre compte,{' '}
                
                
                <a href="/informations-autres" className="text-blue-600">
                  changer votre mot de passe
                </a>{' '}
                et{' '}
                <a href="/informations-autres" className="text-blue-600">
                  les détails de votre compte
                </a>
                .
              </p>
              {/* <h2 className="text-md font-bold mb-4">
                L'objectif est de trouver des auteurs qui souhaitent passer par vous pour vendre leurs
                livres en points de ventes.
              </h2> */}
              <ol className="list-decimal pl-4 space-y-4">
                <li>
                  Trouver des auteurs dans la partie à gauche du site : "Trouver des auteurs"
                </li>
                <li>Discuter avec les membres, dans la partie "CHAT", en haut à droite du site </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompteA;

import React, { useState ,useEffect } from 'react';
import { IoMdArrowDropright } from 'react-icons/io';
import Header1 from './diffuseurs/Header1';
import BASE_URL from '../key';
import Sidebar from '../components/sidebarAuteur';


const DevisAuteur = () => {
 
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false); 
    const [devisList, setDevisList] = useState([]);


    const [formData, setFormData] = useState({
      titreLivre: '',
      nomAuteur: '',
      auteur_id: '',
      identifiant_commande: '',
      isbn_option: 'fourni',
      isbn: '',
      reliure: 'Dos carré collé rigide', 
      format: '14,80 x 21 cm',
      couverture: 'Pelliculé brillant',
      vernis_options: '',
      marquage_metal_plat: 'sans',
      marquage_metal_3d: 'sans',
      pages_noir: '',
      papier_noir: 'offset 80 g/m² blanc',
      pages_quadrichromie: '',
      papier_quadrichromie: 'offset 80 g/m² blanc',
      quantite: '',
      mode_reliure: 'thermo-collage en PUR',
      tranchefile: 'sans', 
      signet: 'sans', 
      nombre_points: '',

  });
  const [livres, setLivres] = useState([]); 
  const [selectedLivre, setSelectedLivre] = useState(null);
  const [error, setError] = useState(''); 
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printData, setPrintData] = useState(null);


  const fetchLivresEtUtilisateur = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        setError('Token manquant');
        return;
    }

    try {
        // Récupérer les données utilisateur
        // const userResponse = await fetch('http://localhost:8000/api/user', {
            const userResponse = await fetch(`${BASE_URL}/user`, {

            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!userResponse.ok) throw new Error('Erreur lors de la récupération des données utilisateur.');

        const userData = await userResponse.json();
        const { id, username, prenom } = userData;

        // Ajouter les données utilisateur au formData
        setFormData((prev) => ({
            ...prev,
            nomAuteur: `${username} ${prenom}`,
            auteur_id: id, // Stocker l'ID pour auteur_id
        }));

        // Récupérer les livres
        // const livresResponse = await fetch('http://localhost:8000/api/livres-Auteur', {
                const livresResponse = await fetch(`${BASE_URL}/livres-Auteur`, {

            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!livresResponse.ok) throw new Error('Erreur lors de la récupération des livres.');

        const livresData = await livresResponse.json();
        setLivres(livresData);
    } catch (err) {
        setError(err.message);
    }
};

useEffect(() => {
    fetchLivresEtUtilisateur();
}, []);


const fetchDevis = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        setError('Token manquant');
        return;
    }

    try {
        // const response = await fetch('http://localhost:8000/api/devis-user', {
                const response = await fetch(`${BASE_URL}/devis-user`, {

            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) throw new Error('Erreur lors de la récupération des devis.');

        const data = await response.json();
        setDevisList(data); 
    } catch (err) {
        setError(err.message);
    }
};

useEffect(() => {
    fetchDevis();
}, []);

const handleSubmitDevis = async (event) => {
    event.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('Token manquant');
        }

        // const response = await fetch('http://localhost:8000/api/devis', {
        const response = await fetch(`${BASE_URL}/devis`, {

            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Erreur lors de la création du devis.');
        }

        const data = await response.json();
        setSuccessMessage('Devis Envoyé avec succès !');
        setShowSuccessModal(true); 
    } catch (err) {
        setError(err.message);
    }
};
const handleCloseModal = () => {
    setShowSuccessModal(false);
};

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(`${name}: ${value}`); 
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const handleLivreChange = (e) => {
        const livreId = parseInt(e.target.value, 10);
        const selected = livres.find((livre) => livre.id === livreId);
        setSelectedLivre(selected || null);
      };
      

   // Imprimer un devis
    const handlePrint = (devis) => {
        setPrintData(devis); 
        setShowPrintModal(true);
    };

    const handleClosePrintModal = () => {
        setShowPrintModal(false);
        setPrintData(null);
    };

    const handlePrintPage = () => {
        window.print(); // Imprime la page
    };

    return (
        <div className="bg-white min-h-screen">
            <Header1 />

            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {/* Sidebar */}
                    {/* <div className="bg-white shadow-md rounded-lg p-4">
 <ul className="space-y-4">
   <li className="group">
     <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
       </span>
       Tableau de bord
     </a>
   </li>
   <li className="group">
     <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
       </span>
       Mes livres
     </a>
   </li>
   <li className="group">
     <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
       </span>
       Trouver des professionnels
     </a>
   </li>
   <li className="group">
     <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
       </span>
 Mes Informations    </a>
   </li>
 
   <li className="group">
     <a href="/devis-auteurs" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
     <span className="inline-block text-green-500 mr-2">
       <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
     </span>
       Devis Impression / demande
     </a>
   </li>
 
   <li className="group">
     <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
       </span>
       Vente de livres
     </a>
   </li>

   <li className="group">
    <a href="/stock" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
       </span>
     Gestion de Stock
    </a>
  </li>
  
  <li className="group">
                <a
                  href="/payments"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Mes paiements
                </a>
              </li>
 </ul>


 </div> */}

 <Sidebar />


                    {/* Main Content */}
                    <div className="md:col-span-3">
                        {/* Liste des devis */}
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
    <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">Mes Devis</h2>
    {devisList.length > 0 ? (
        <ul>
            {devisList.map((devis) => (
                <li
                    key={devis.id}
                    className="grid grid-cols-3 items-center border-b py-2 gap-4"
                >
                    {/* Colonne 1: Titre du livre */}
                    <span className="text-gray-700 font-medium">
                        {devis.livre?.title || "Titre non disponible"}
                    </span>

                    {/* Colonne 2: Auteur et statut */}
                    <div className="flex items-center space-x-4">
                    

                        {/* Statut */}
                        <span
                            className={`${
                                devis.status === "Validé"
                                    ? "text-green-500"
                                    : "text-yellow-500"
                            } font-bold`}
                        >
                            {devis.status}
                        </span>
                    </div>

                    {/* Colonne 3: Bouton imprimer */}
                    {/* {devis.status === "Validé" && ( */}
                        <button
                            onClick={() => handlePrint(devis)}
                            className="text-green-500 underline"
                        >
                            Voir
                        </button>
                    {/* )} */}
                </li>
            ))}
        </ul>
    ) : (
        <p className="text-gray-600">Aucun devis trouvé.</p>
    )}
</div>


                        {/* Formulaire de demande de devis */}
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
                            <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">
                                Demande de Devis
                            </h2>
                            <form onSubmit={handleSubmitDevis}>
                                {/* Titre du livre */}
                                <div className="mb-4">
                  <label className="block font-bold mb-2">Titre du Livre :</label>
                  <select
                    name="livre_id"
                    value={formData.livre_id}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 w-full rounded-md"
                    required
                  >
                    <option value="">Sélectionnez un livre</option>
                    {livres.map((livre) => (
                      <option key={livre.id} value={livre.id}>
                        {livre.title}
                      </option>
                    ))}
                  </select>
                </div>

                                {/* Nom de l'auteur */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">Nom de l'auteur :</label>
                                    <input
                                        type="text"
                                        name="nomAuteur"
                                        value={formData.nomAuteur}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                        placeholder="Entrez le nom de l'auteur"
                                        required
                                    />
                                </div>

                                {/* Identifiant de la commande */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">
                                        Identifiant de la commande :
                                    </label>
                                    <input
                                        type="text"
                                        name="identifiant_commande"
                                        value={formData.identifiant_commande}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                        placeholder="Entrez l'identifiant de la commande"
                                    />
                                </div>

                                {/* ISBN */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">ISBN :</label>
                                    <div className="flex items-center space-x-4">
                                        <label className="flex items-center space-x-2">
                                            <input
                                                type="radio"
                                                name="isbn_option"
                                                value="fourni"
                                                checked={formData.isbn_option === 'fourni'}
                                                onChange={handleChange}
                                            />
                                            <span>Fourni par Editions7</span>
                                        </label>
                                        <label className="flex items-center space-x-2">
                                            <input
                                                type="radio"
                                                name="isbn_option"
                                                value="propre"
                                                checked={formData.isbn_option === 'propre'}
                                                onChange={handleChange}
                                            />
                                            <span>Je fournis mon ISBN</span>
                                        </label>
                                    </div>
                                    {formData.isbn_option === 'propre' && (
                                        <input
                                            type="text"
                                            name="isbn"
                                            value={formData.isbn}
                                            onChange={handleChange}
                                            className="border border-gray-300 p-2 w-full rounded-md mt-2"
                                            placeholder="Entrez votre ISBN"
                                        />
                                    )}
                                </div>

                                {/* Reliure */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">Reliure :</label>
                                    <select
                                        name="reliure"
                                        value={formData.reliure}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                    >
                                        <option>Dos carré collé</option>
                                        <option>Dos carré collé rigide</option>
                                    </select>
                                </div>

                                {/* Format */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">Format :</label>
                                    <select
                                        name="format"
                                        value={formData.format}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                    >
                                        <option>14,80 x 21 cm</option>
                                        <option>21 x 29,7 cm</option>
                                        <option>21 x 21 cm</option>
                                        <option>11 x 17 cm</option>
                                        <option>11 x 18 cm</option>
                                        <option>11 x 20 cm</option>
                                        <option>15 x 15 cm</option>
                                        <option>14 x 18 cm</option>
                                        <option>12 x 18 cm</option>
                                        <option>12 x 19 cm</option>
                                    </select>
                                </div>

                                {/* Couverture */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">Couverture :</label>
                                    <select
                                        name="couverture"
                                        value={formData.couverture}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                    >
                                        <option>Pelliculé brillant</option>
                                        <option>Pelliculé mat</option>
                                        <option>Pelliculé soft touch (peau de pêche)</option>
                                    </select>
                                </div>

                                {/* Options supplémentaires pour Pelliculé mat ou soft touch */}
                                {(formData.couverture === 'Pelliculé mat' ||
                                    formData.couverture === 'Pelliculé soft touch (peau de pêche)') && (
                                    <>
                                        {/* Vernis sélectif */}
                                        <div className="mb-4">
                                            <label className="block font-bold mb-2 text-[#737373]">
                                                Vernis sélectif :
                                            </label>
                                            <select
                                                name="vernis_options"
                                                value={formData.vernis_options}
                                                onChange={handleChange}
                                                className="border border-gray-300 p-2 w-full rounded-md"
                                            >
                                                <option>sans</option>
                                                <option>Vernis sélectif</option>
                                                <option>Vernis sélectif à plat</option>
                                                <option>Vernis sélectif 3D</option>
                                                <option>Vernis sélectif à plat et 3D</option>
                                            </select>
                                        </div>
                                          <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">
                                        Marquage aspect métal plat :
                                    </label>
                                    <select
                                        name="marquage_metal_plat"
                                        value={formData.marquage_metal_plat}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                    >
                                        <option>sans</option>
                                        <option>argent</option>
                                        <option>or</option>
                                        <option>argent et or</option>
                                    </select>
                                </div>

                                {/* Marquage aspect métal 3D */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">
                                        Marquage aspect métal 3D :
                                    </label>
                                    <select
                                        name="marquage_metal_3d"
                                        value={formData.marquage_metal_3d}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                    >
                                        <option>sans</option>
                                        <option>argent</option>
                                        <option>or</option>
                                        <option>argent et or</option>
                                    </select>
                                </div>
                                    </>
                                )}

                                 {(formData.couverture === 'Pelliculé brillant' ||
                                    formData.couverture === 'Pelliculé brillant') && (
                                    <>
                                        {/* Vernis sélectif */}
                                        {/* <div className="mb-4">
                                            <label className="block font-bold mb-2 text-[#737373]">
                                                Vernis sélectif :
                                            </label>
                                            <select
                                                name="vernis_options"
                                                value={formData.vernis_options}
                                                onChange={handleChange}
                                                className="border border-gray-300 p-2 w-full rounded-md"
                                            >
                                                <option>sans</option>
                                                <option>Vernis sélectif</option>
                                                <option>Vernis sélectif à plat</option>
                                                <option>Vernis sélectif 3D</option>
                                                <option>Vernis sélectif à plat et 3D</option>
                                            </select>
                                        </div> */}
                                 
                                    </>
                                )}

                                {/* Marquage aspect métal plat */}
                              

                                {/* Intérieur */}
                                <h3 className="text-lg font-bold mb-2 mt-6">Intérieur</h3>

                                {/* Nombre de pages en impression noire */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">
                                        Nombre de pages en impression noire :
                                    </label>
                                    <input
                                        type="number"
                                        name="pages_noir"
                                        value={formData.pages_noir}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                        placeholder="Entrez le nombre de pages en noir"
                                    />
                                </div>

                                {/* Type de papier pour impression noire */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">
                                        Type de papier intérieur pour les pages en impression noire :
                                    </label>
                                    <select
                                        name="papier_noir"
                                        value={formData.papier_noir}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                    >
                                        <option>offset 80 g/m² blanc</option>
                                        <option>offset 90 g/m² blanc</option>
                                        <option>bouffant 90 g/m² blanc</option>
                                        <option>bouffant 90 g/m² ivoire</option>
                                        <option>couché brillant 115 g/m²</option>
                                        <option>couché brillant 150 g/m²</option>
                                        <option>couché mat 115 g/m²</option>
                                        <option>couché mat 150 g/m²</option>
                                    </select>
                                </div>

                                {/* Nombre de pages en impression quadrichromie */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">
                                        Nombre de pages en impression quadrichromie :
                                    </label>
                                    <input
                                        type="number"
                                        name="pages_quadrichromie"
                                        value={formData.pages_quadrichromie}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                        placeholder="Entrez le nombre de pages en quadrichromie"
                                    />
                                </div>

                                {/* Type de papier pour impression quadrichromie */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">
                                        Type de papier intérieur pour les pages en impression quadrichromie :
                                    </label>
                                    <select
                                        name="papier_quadrichromie"
                                        value={formData.papier_quadrichromie}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                    >
                                        <option>offset 80 g/m² blanc</option>
                                        <option>offset 90 g/m² blanc</option>
                                        <option>bouffant 90 g/m² blanc</option>
                                        <option>bouffant 90 g/m² ivoire</option>
                                        <option>couché brillant 115 g/m²</option>
                                        <option>couché brillant 150 g/m²</option>
                                        <option>couché mat 115 g/m²</option>
                                        <option>couché mat 150 g/m²</option>
                                    </select>
                                </div>

                                {/* Quantité */}
                                <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">Quantité :</label>
                                    <input
                                        type="number"
                                        name="quantite"
                                        value={formData.quantite}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                        placeholder="Entrez la quantité"
                                        required
                                    />
                                </div>
                           
                                {/* Livraison */}
                                <div className="mb-4">
                                    <p className="text-[#737373]">
                                        Livraison entre 9 et 14 jours ouvrés pour les livres en dos carré collé souple.
                                    </p>
                                    <p className="text-[#737373]">
                                        Livraison entre 11 et 21 jours ouvrés pour les livres en dos carré collé rigide.
                                    </p>
                                </div>

                                     {/* Dos carré collé rigide */}
                                     {formData.reliure === 'Dos carré collé rigide' && (
                                    <>
                                        <h3 className="text-lg font-bold mb-2 mt-6">Dos carré collé rigide</h3>

                                        {/* Mode de reliure */}
                                        <div className="mb-4">
                                            <label className="block font-bold mb-2 text-[#737373]">Mode de reliure :</label>
                                            <select
                                                name="mode_reliure"
                                                value={formData.mode_reliure}
                                                onChange={handleChange}
                                                className="border border-gray-300 p-2 w-full rounded-md"
                                            >
                                                <option>thermo-collage en PUR</option>
                                                <option>cousu</option>
                                            </select>
                                        </div>

                                        {/* Tranchefile */}
                                        <div className="mb-4">
                                            <label className="block font-bold mb-2 text-[#737373]">Tranchefile :</label>
                                            <select
                                                name="tranchefile"
                                                value={formData.tranchefile}
                                                onChange={handleChange}
                                                className="border border-gray-300 p-2 w-full rounded-md"
                                            >
                                                <option>sans</option>
                                                <option>noir</option>
                                                <option>rouge</option>
                                                <option>bleu foncé</option>
                                                <option>vert</option>
                                                <option>jaune</option>
                                                <option>blanc</option>
                                                <option>brun</option>
                                                <option>écru</option>
                                            </select>
                                        </div>

                                        {/* Signet */}
                                        <div className="mb-4">
                                            <label className="block font-bold mb-2 text-[#737373]">Signet :</label>
                                            <select
                                                name="signet"
                                                value={formData.signet}
                                                onChange={handleChange}
                                                className="border border-gray-300 p-2 w-full rounded-md"
                                            >
                                                <option>sans</option>
                                                <option>noir</option>
                                                <option>rouge</option>
                                                <option>bleu foncé</option>
                                                <option>vert</option>
                                                <option>jaune</option>
                                                <option>blanc</option>
                                                <option>brun</option>
                                                <option>écru</option>
                                            </select>
                                        </div>
                                    </>
                                )}

                                  {/* Quantité */}
                                  <div className="mb-4">
                                    <label className="block font-bold mb-2 text-[#737373]">Nombre d'adresses de livraison :</label>
                                    <input
                                        type="number"
                                        name="nombre_points"
                                        value={formData.nombre_points}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 w-full rounded-md"
                                        placeholder="Entrez la quantité"
                                        required
                                    />
                                </div>


                                {/* Bouton Envoyer */}
                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                    >
                                        Envoyer
                                    </button>
                                </div>



                            </form>
                        </div>
                    </div>
                </div>
            </div>
               {/* Modal de succès */}
               {showSuccessModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-8 w-96">
                        <h3 className="text-green-500 text-lg font-bold mb-4">Succès</h3>
                        <p className="text-gray-700 mb-6">{successMessage}</p>
                        <button
                            onClick={handleCloseModal}
                            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 w-full"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}

          {/* Modal pour imprimer un devis */}
{showPrintModal && printData && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
<div className="bg-white rounded-lg shadow-lg p-8 w-2/3 max-w-4xl">
<h3 className="text-lg font-bold mb-4">Détails du devis</h3>
      <div className="space-y-2">
        <p>
          <strong>Titre du livre :</strong> {printData.livre?.title || "Non disponible"}
        </p>
        <p>
        <strong>Nom de l'auteur :</strong> {printData.auteur?.username} {printData.auteur?.prenom}
        </p>
         <p>
        <strong>Nom de l'auteur :</strong> {printData.auteur?.username} {printData.auteur?.prenom}
        </p>
        <p>
          <strong>Prix :</strong> {printData.prix || "Non disponible"}
        </p>
        <p>
          <strong>Identifiant de la commande :</strong> {printData.identifiant_commande || "Non disponible"}
        </p>
        <p>
          <strong>ISBN :</strong>{" "}
          {printData.isbn_option === "fourni"
            ? "Fourni par Editions7"
            : printData.isbn || "ISBN propre"}
        </p>
        <p>
          <strong>Reliure :</strong> {printData.reliure}
        </p>
        <p>
          <strong>Format :</strong> {printData.format}
        </p>
        <p>
          <strong>Couverture :</strong> {printData.couverture}
        </p>
        {["Pelliculé mat", "Pelliculé soft touch (peau de pêche)"].includes(printData.couverture) && (
          <p>
            <strong>Vernis Sélectif :</strong> {printData.vernis_options || "Non disponible"}
          </p>
        )}
        <p>
          <strong>Marquage Métal Plat :</strong> {printData.marquage_metal_plat}
        </p>
        <p>
          <strong>Marquage Métal 3D :</strong> {printData.marquage_metal_3d}
        </p>
        <p>
          <strong>Pages en noir :</strong> {printData.pages_noir || "Non spécifié"}
        </p>
        <p>
          <strong>Papier (noir) :</strong> {printData.papier_noir}
        </p>
        <p>
          <strong>Pages en quadrichromie :</strong> {printData.pages_quadrichromie || "Non spécifié"}
        </p>
        <p>
          <strong>Papier (quadrichromie) :</strong> {printData.papier_quadrichromie}
        </p>
      
        {printData.reliure === "Dos carré collé rigide" && (
          <>
            <p>
              <strong>Mode de reliure :</strong> {printData.mode_reliure}
            </p>
            <p>
              <strong>Tranchefile :</strong> {printData.tranchefile}
            </p>
            <p>
              <strong>Signet :</strong> {printData.signet}
            </p>

          

            
          </>
          
        )}

<p>
          <strong>Quantité :</strong> {printData.quantite}
        </p>
          <p>
          <strong>Nombre d'adresses de livraison :</strong> {printData.nombre_points}
        </p>
      </div>
      <div className="flex justify-between mt-6">
        <button
          onClick={handleClosePrintModal}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg"
        >
          Annuler
        </button>
        <button
          onClick={() => window.print()}
          className="bg-green-500 text-white px-4 py-2 rounded-lg"
        >
          Imprimer
        </button>
      </div>
    </div>
  </div>
)}

        </div>
    );
};

export default DevisAuteur;

import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Sidebar = () => {
  const location = useLocation();
  const [showInvoicesDropdown, setShowInvoicesDropdown] = useState(false);

  // Check if the current route matches any dropdown route
  useEffect(() => {
    if (
      location.pathname === "/payments" ||
      location.pathname === "/factures-impression"
    ) {
      setShowInvoicesDropdown(true);
    }
  }, [location.pathname]);

  const toggleInvoicesDropdown = () => {
    setShowInvoicesDropdown(!showInvoicesDropdown);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <ul className="space-y-4">
        <li className="group">
          <NavLink
            to="/compte-diffuseur"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
            }
          >
            <span className="inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Tableau de bord
          </NavLink>
        </li>

        <li className="group">
          <NavLink
            to="/information-diffuseur"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
            }
          >
            <span className="inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Mes Informations
          </NavLink>
        </li>

        <li className="group">
          <NavLink
            to="/rechercher-auteurs"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
            }
          >
            <span className="inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Trouver des auteurs
          </NavLink>
        </li>

        <li className="group">
          <NavLink
            to="/points-ventes"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
            }
          >
            <span className="inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Gestion de points de vente
          </NavLink>
        </li>

        <li className="group">
          <NavLink
            to="/mes-ventes"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
            }
          >
            <span className="inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Mes Ventes/Factures
          </NavLink>
        </li>

        <li className="group">
          <NavLink
            to="/factures"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
            }
          >
            <span className="inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Facture d'abonnement
          </NavLink>
        </li>

        
        <li className="group">
          <NavLink
            to="/livres-diffuser"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
            }
          >
            <span className="inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Mes livres diffusés
          </NavLink>
        </li>

        {/* <li className="group">
          <div
            className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center cursor-pointer"
            onClick={toggleInvoicesDropdown}
          >
            <span className="inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Mes Factures
            <span className="ml-auto">
              {showInvoicesDropdown ? (
                <FaChevronUp className="text-green-500 h-4 w-4" />
              ) : (
                <FaChevronDown className="text-green-500 h-4 w-4" />
              )}
            </span>
          </div>
          {showInvoicesDropdown && (
            <ul className="pl-8 mt-2 space-y-2">
              <li>
                <NavLink
                  to="/factures-abonnement"
                  className={({ isActive }) =>
                    isActive
                      ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                      : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                  }
                >
                  Facture d'abonnement
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/factures-impression"
                  className={({ isActive }) =>
                    isActive
                      ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                      : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                  }
                >
                  Facture d'impression de livres
                </NavLink>
              </li>
            </ul>
          )}
        </li> */}

        {/* <li className="group">
          <NavLink
            to="/factures"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
            }
          >
            <span className="inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Mes Commandes d'impression
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import Header1 from "./diffuseurs/Header1";
import BASE_URL from "../key";
import { FaTrashAlt } from 'react-icons/fa'; // Si tu utilises react-icons


const ChatAppdiff = () => {
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentUserId, setCurrentUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({}); // Définir ici pour les messages non lus
  const [conversationToDelete, setConversationToDelete] = useState(null); // Définir ici pour la conversation à supprimer
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Définir ici pour afficher la modale de suppression
  const [allUsers, setAllUsers] = useState([]);


  useEffect(() => {
    const fetchAuthenticatedUser = async () => {
      try {
        const response = await fetch(`${BASE_URL}/user`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        if (response.ok) {
          const data = await response.json();
          setCurrentUserId(data.id);
        }
      } catch (error) {
        console.error("Erreur utilisateur :", error);
      }
    };
    fetchAuthenticatedUser();
  }, []);

  useEffect(() => {
    fetchMembers();
    fetchGroups();
    fetchAllUsers();
  }, []);


  const fetchAllUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/auteur`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.ok) {
        const data = await response.json();
        setAllUsers(data.data || []);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs :", error);
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/members`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = await response.json();
      setMembers(data);
      setFilteredMembers(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des membres :", error);
    }
  };
    // ✅ Créer un groupe
    const handleCreateGroup = async () => {
      if (!groupName.trim() || selectedMembers.length === 0) {
        alert("Veuillez renseigner un nom de groupe et sélectionner des membres.");
        return;
      }
  
      try {
        const response = await fetch(`${BASE_URL}/discussions`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            is_group: true,
            group_name: groupName,
            participants: selectedMembers,
          }),
        });
  
        if (response.ok) {
          fetchGroups();
          setShowGroupModal(false);
          setGroupName("");
          setSelectedMembers([]);
          alert("Groupe créé avec succès !");
        } else {
          console.error("Erreur lors de la création du groupe.");
        }
      } catch (error) {
        console.error("Erreur réseau :", error);
      }
    };

  const fetchGroups = async () => {
    try {
      const response = await fetch(`${BASE_URL}/groups`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.ok) {
        const data = await response.json();
        setGroups(data.groups || []);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des groupes :", error);
    }
  };

  // Rafraîchir les messages non lus toutes les 2 minutes
  useEffect(() => {
    const fetchUnreadMessages = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/messages/unread", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const unreadData = await response.json();
          setMembers((prev) =>
            prev.map((member) => ({
              ...member,
              unreadCount: unreadData[member.id] || 0, // Mettre à jour les messages non lus
            }))
          );
          setGroups((prevGroups) =>
            prevGroups.map((group) => ({
              ...group,
              unreadCount: unreadData[group.id] || 0, // Mettre à jour les messages non lus pour les groupes
            }))
          );
        } else {
          console.error("Erreur lors de la récupération des messages non lus.");
        }
      } catch (error) {
        console.error("Erreur réseau lors de la récupération des messages non lus :", error);
      }
    };

    // Appel initial
    fetchUnreadMessages();

    // Intervalle toutes les 2 minutes
    const interval = setInterval(fetchUnreadMessages, 120000);

    return () => clearInterval(interval); // Nettoyage de l'intervalle
  }, []);

  // ✅ Récupérer les messages + compter les non lus
  const fetchMessages = async (conversationId, isGroup = false) => {
    if (!conversationId) return;

    try {
      const endpoint = isGroup
        ? `${BASE_URL}/groups/${conversationId}/messages`
        : `${BASE_URL}/messages/${conversationId}`;

      const response = await fetch(endpoint, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (!response.ok) throw new Error("Erreur lors de la récupération des messages.");

      const data = await response.json();
      const messagesArray = Array.isArray(data) ? data : data.messages || [];

      setMessages(messagesArray);

      // ✅ Réinitialiser les messages non lus pour cette conversation
      setUnreadCounts((prevCounts) => ({
        ...prevCounts,
        [conversationId]: 0,
      }));
    } catch (error) {
      console.error("❌ Erreur lors de la récupération des messages :", error);
      setMessages([]);
    }
  };

  // ✅ Envoyer un message
  const handleSendMessage = async () => {
    if (!newMessage.trim()) {
      alert("Le message ne peut pas être vide.");
      return;
    }

    try {
      const isGroup = selectedConversation?.type === "group";
      const endpoint = isGroup
        ? `${BASE_URL}/groups/${selectedConversation.id}/send`
        : `${BASE_URL}/messages/send`;

      const body = isGroup
        ? { content: newMessage }
        : { receiver_id: selectedConversation.id, content: newMessage };

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) throw new Error("Erreur lors de l'envoi du message.");

      const data = await response.json();

      // ✅ Ajouter le message envoyé à la liste
      setMessages((prev) => [...prev, data.message]);
      setNewMessage("");
    } catch (error) {
      console.error("❌ Erreur lors de l'envoi du message :", error);
    }
  };

  // ✅ Sélectionner une conversation
  const handleSelectConversation = (conversation, isGroup = false) => {
    setSelectedConversation({ ...conversation, type: isGroup ? "group" : "private" });
    setMessages([]); // Réinitialiser les messages
    fetchMessages(conversation.id, isGroup);
  };



  const handleDeleteConversation = (conversation) => {
    setConversationToDelete(conversation);
    // Afficher la modale de suppression
    setShowDeleteModal(true);
  };

  // ✅ Confirmer la suppression de la conversation
  const confirmDeleteConversation = () => {
    if (!conversationToDelete) return;

    // Suppression locale : filtre les membres et groupes pour ne garder que ceux qui ne sont pas supprimés
    if (conversationToDelete.type === "private") {
      setMembers((prevMembers) => prevMembers.filter(member => member.id !== conversationToDelete.id));
    } else {
      setGroups((prevGroups) => prevGroups.filter(group => group.id !== conversationToDelete.id));
    }

    // Fermer la modale de suppression
    setShowDeleteModal(false);
    // Réinitialiser la conversation sélectionnée après la suppression
    setSelectedConversation(null);
  };

  return (
    <div>
      <Header1 />
      <div className="flex h-screen">
        {/* Membres */}
        <div className="w-1/4 bg-gray-200 p-4 overflow-y-auto">
          <h3 className="text-md font-semibold mt-4">👤 Membres</h3>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Rechercher par prénom, rôle, etc."
            className="w-full p-2 mb-4 border border-gray-300 rounded-md"
          />
          {filteredMembers.map((member) => (
            <div
              key={member.id}
              className="p-2 cursor-pointer rounded-md flex justify-between items-center"
              onClick={() => handleSelectConversation(member)}
            >
              <p className="font-semibold">{member.prenom} {member.username}</p>
              {member.unreadCount > 0 && (
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                  {member.unreadCount}
                </span>
              )}
            </div>
          ))}
          <button onClick={() => setShowGroupModal(true)} className="w-full bg-green-500 text-white px-4 py-2 rounded-md">
            + Créer un Groupe
          </button>
          <h3 className="text-md font-semibold mt-4">📌 Groupes</h3>
          {groups.map((group) => (
            <div key={group.id} className="p-2 cursor-pointer rounded-md bg-blue-100 hover:bg-blue-300"
              onClick={() => handleSelectConversation(group, true)}>
              <p className="font-semibold">{group.group_name}</p>
            </div>
          ))}
        </div>

        {/* Messages */}
        <div className="w-3/4 flex flex-col">
          {selectedConversation ? (
            <>
              <div className="bg-gray-300 p-4">
                <h2 className="text-lg font-bold">
                  {selectedConversation.type === "group"
                    ? selectedConversation.group_name
                    : `${selectedConversation.prenom} ${selectedConversation.username}`}
                </h2>
              </div>

              {/* Liste des messages */}
              <div className="flex-1 overflow-y-auto p-4 space-y-4">
                {messages.length > 0 ? (
                  messages.map((msg, idx) => (
                    <div key={idx} className={`flex ${msg.sender_id === currentUserId ? "justify-end" : "justify-start"}`}>
                      <div className={`max-w-xs p-3 rounded-lg ${msg.sender_id === currentUserId ? "bg-green-200 text-black" : "bg-gray-200"}`}>
                        <p className="text-sm font-semibold">{msg.sender_prenom || "Inconnu"} {msg.sender_username || ""}</p>
                        <p>{msg.content}</p>
                        <p className="text-xs text-gray-500 mt-1 text-right">{new Date(msg.created_at).toLocaleString()}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Aucun message dans cette conversation.</p>
                )}
              </div>

              {/* Champ d'entrée + Bouton "Envoyer" */}
              <div className="bg-gray-300 p-4 flex items-center">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="flex-1 p-2 border border-gray-300 rounded-md"
                  placeholder="Écrivez un message..."
                />
                <button onClick={handleSendMessage} className="ml-4 bg-green-500 text-white px-4 py-2 rounded-md">
                  Envoyer
                </button>
              </div>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center">
              <p>Sélectionnez une conversation.</p>
            </div>
          )}
        </div>
      </div>

      {/* Modal de création de groupe */}
      {showGroupModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-1/3">
            <h2 className="text-lg font-bold mb-4">Créer un Groupe</h2>
            <input
              type="text"
              placeholder="Nom du groupe"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md mb-4"
            />
            <h3 className="font-semibold mb-2">Sélectionnez des utilisateurs :</h3>
            <div className="max-h-40 overflow-y-auto">
              {allUsers.length > 0 ? (
                allUsers.map((user) => (
                  <div key={user.id} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      value={user.id}
                      checked={selectedMembers.includes(user.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedMembers([...selectedMembers, user.id]);
                        } else {
                          setSelectedMembers(selectedMembers.filter((id) => id !== user.id));
                        }
                      }}
                    />
                    <p className="ml-2">{user.prenom} {user.username} <span className="text-gray-500">({user.role})</span></p>
                  </div>
                ))
              ) : (
                <p>Aucun utilisateur disponible.</p>
              )}
            </div>
            <div className="flex justify-end mt-4">
              <button onClick={() => setShowGroupModal(false)} className="mr-4 bg-gray-500 text-white px-4 py-2 rounded-md">
                Annuler
              </button>
              <button onClick={handleCreateGroup} className="bg-green-500 text-white px-4 py-2 rounded-md">
                Créer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatAppdiff;

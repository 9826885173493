import React, { useState, useEffect } from 'react';
import { IoMdCreate, IoMdTrash, IoMdArrowDropright } from 'react-icons/io';
import Header1 from './diffuseurs/Header1';
import axios from 'axios';
import BASE_URL from '../key';
import Sidebar from '../components/sidebarAuteur';

const Livre = () => {
    const [livres, setLivres] = useState([]); 
    const [error, setError] = useState(''); 
    const [showModal, setShowModal] = useState(false); 
    const [showEditModal, setShowEditModal] = useState(false); 
    const [selectedBook, setSelectedBook] = useState({}); 
    const [newBook, setNewBook] = useState({}); 
    const [currentPage, setCurrentPage] = useState(1); 
    const rowsPerPage = 20; // Number of rows per page
    const [showSuccessModal, setShowSuccessModal] = useState(false); 
    const [bookToDelete, setBookToDelete] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSuccessModalEdit, setShowSuccessModalEdit] = useState(false);

    

    // Fonction pour récupérer les livres depuis l'API
    const fetchLivresDiffuseur = async () => {
        setError('');
        try {
            // const response = await fetch('http://localhost:8000/api/livres-Auteur', {
                const response = await fetch(`${BASE_URL}/livres-Auteur`, {

                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des livres.');
            }

            const data = await response.json();
            setLivres(data);
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchLivresDiffuseur();
    }, []);

    // Handle pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = livres.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(livres.length / rowsPerPage);

    // const handlePageChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };

    // Modal functions
    const handleAddBook = () => {
        setShowModal(true);
    };

    const handleEditBook = (book) => {
        setSelectedBook(book);
        setShowEditModal(true);
    };

  
    const handleSubmitBook = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('title', newBook.title);
            formData.append('description', newBook.description);
            formData.append('price', newBook.price);
            formData.append('sales_target', newBook.sales_target);
            formData.append('stock_auteur', newBook.stock_auteur);

    
            if (newBook.cover_first) {
                formData.append('cover_first', newBook.cover_first);
            }
    
            if (newBook.cover_fourth) {
                formData.append('cover_fourth', newBook.cover_fourth);
            }
    
            // const response = await fetch('http://localhost:8000/api/livres-store', {
            const response = await fetch(`${BASE_URL}/livres-store`, {

                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData,
            });
    
            if (!response.ok) throw new Error('Erreur lors de l\'ajout du livre.');
    
            const data = await response.json();
            console.log('Livre ajouté avec succès :', data);
    
            // Afficher le modal de succès
            setShowSuccessModal(true);
    
            // Réinitialiser le formulaire
            setNewBook({});
            setShowModal(false);
    
            // Fermer le modal de succès et rafraîchir après 3 secondes
            setTimeout(() => {
                setShowSuccessModal(false);
                window.location.reload(); // Recharge la page
            }, 3000);
        } catch (error) {
            console.error('Erreur :', error.message);
        }
    };
    
    
    
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        localStorage.setItem('currentPage', pageNumber); // Save the current page in localStorage
    };
    const handleDeleteBook = async () => {
        if (!bookToDelete) return;

        try {
            // await axios.delete(`http://localhost:8000/api/supprimer-livres/${bookToDelete.id}`, {
                await axios.delete(`${BASE_URL}/supprimer-livres/${bookToDelete.id}`, {

                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            setShowDeleteModal(false);
            setBookToDelete(null);

            // Refresh the book list
            fetchLivresDiffuseur();
        } catch (error) {
            console.error('Erreur lors de la suppression du livre:', error.response || error.message);
            alert('Erreur lors de la suppression du livre');
        }
    };

    // const handleEditBook = (book) => {
    //     setSelectedBook(book);
    //     setShowEditModal(true);
    // };

    const handleSubmitEditBook = async (event) => {
        event.preventDefault();
    
        try {
            const jsonPayload = {
                title: selectedBook.title,
                description: selectedBook.description,
                price: selectedBook.price,
                sales_target: selectedBook.sales_target,
                stock_auteur: selectedBook.stock_auteur,

            };
    
            await axios.put(
                // `http://localhost:8000/api/livres-modifier/${selectedBook.id}`,
                `${BASE_URL}/livres-modifier/${selectedBook.id}`,

                jsonPayload,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (selectedBook.cover_first || selectedBook.cover_fourth) {
                const formData = new FormData();
    
                if (selectedBook.cover_first instanceof File) {
                    formData.append('cover_first', selectedBook.cover_first);
                }
                if (selectedBook.cover_fourth instanceof File) {
                    formData.append('cover_fourth', selectedBook.cover_fourth);
                }
    
               
                await axios.post(
                    // `http://localhost:8000/api/livres-upload-images/${selectedBook.id}`,
                     `${BASE_URL}/livres-upload-images/${selectedBook.id}`,

                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            }
    
            // Fermer le modal d'édition et afficher un message de succès
            setShowEditModal(false); // Fermer le modal d'édition
        setShowSuccessModalEdit(true); // Afficher le modal de succès
    
            // Rafraîchir la liste des livres après 3 secondes
            setTimeout(() => {
                setShowSuccessModal(false);
                fetchLivresDiffuseur(); // Récupère les livres sans recharger la page
            }, 3000);
        } catch (error) {
            console.error('Erreur lors de la mise à jour du livre:', error.response || error.message);
    
            // Afficher un message d'erreur utilisateur
            alert(
                error.response?.data?.message ||
                'Erreur lors de la mise à jour des informations. Veuillez vérifier les données saisies.'
            );
        }
    };
    

    const handleCloseEditModal = () => setShowEditModal(false);

    return (
        <div className="bg-white min-h-screen">
            <Header1 />
            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                {/* Sidebar */}
         {/* <div className="bg-white shadow-md rounded-lg p-4">
<ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
    <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
Mes Informations    </a>
  </li>
  <li className="group">
    <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
      Mes livres
    </a>
  </li>

 
  <li className="group">
    <a href="/devis-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Devis Impression / demande
    </a>
  </li>
  

  <li className="group">
    <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Vente de livres
    </a>
  </li>
  <li className="group">
    <a href="/stock" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
       </span>
     Gestion de Stock
    </a>
  </li>
  <li className="group">
                <a
                  href="/payments"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Mes paiements
                </a>
              </li>
</ul>


</div> */}

<Sidebar />

                    {/* Main Content */}
                    <div className="md:col-span-3">
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
                            <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">
                                MES LIVRES
                            </h2>

                            <div className="flex justify-end mb-4">
                                <button
                                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                    onClick={handleAddBook}
                                >
                                    Ajouter un livre
                                </button>
                            </div>

                            <table className="table-auto border-collapse border border-gray-400 w-full">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border border-gray-300 p-2">Nom du livre</th>
                                        <th className="border border-gray-300 p-2">Description</th>
                                        <th className="border border-gray-300 p-2">Prix</th>
                                        <th className="border border-gray-300 p-2">Objectif de vente</th>
                                        <th className="border border-gray-300 p-2">Couverture</th>
                                        <th className="border border-gray-300 p-2">Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {currentRows.map((livre) => (
                                        <tr key={livre.id}>
                                            <td className="border border-gray-300 p-2">{livre.title}</td>
                                            <td className="border border-gray-300 p-2">{livre.description}</td>
                                            <td className="border border-gray-300 p-2">{livre.price}€</td>
                                            <td className="border border-gray-300 p-2">{livre.sales_target}</td>
                                            {/* <td className="p-2 border border-gray-300">
                                                <div className="flex space-x-2">
                                                    {livre.cover_images &&
                                                    Array.isArray(livre.cover_images) &&
                                                    livre.cover_images.length > 0 ? (
                                                        livre.cover_images.map((image, index) => (
                                                            <img
                                                                key={index}
                                                                src={image}
                                                                alt={`Couverture ${index + 1}`}
                                                                className="w-16 h-16 object-cover rounded shadow-md"
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="text-gray-500 italic">
                                                            Aucune couverture disponible
                                                        </p>
                                                    )}
                                                </div>
                                            </td> */}
  <td className="border border-gray-300 p-2">
    <div className="flex items-center justify-center space-x-4">
        {/* Première couverture */}
        <div className="flex flex-col items-center">
            {livre.cover_first ? (
                <img
                    src={livre.cover_first.startsWith('http') 
                    ? livre.cover_first 
                    // : `http://localhost:8000/storage/${livre.cover_first}`}
                    : `https://editions7.com/admin/public/storage/${livre.cover_first}`}

                    
                    // src={`https://editions7.com/storage/cover_images/${livre.cover_first}?t=${new Date().getTime()}`}


                    alt="1ère couverture"
                    className="w-20 h-20 object-cover rounded shadow-md"
                />
            ) : (
                <p className="text-gray-500 italic">Pas de 1ère couverture</p>
            )}
            <p className="text-sm font-bold text-gray-700 mt-1">Cover 1</p>
        </div>

        {/* Quatrième couverture */}
        <div className="flex flex-col items-center">
            {livre.cover_fourth ? (
                <img
                src={livre.cover_fourth.startsWith('http') 
                ? livre.cover_fourth 
                // : `http://localhost:8000/storage/${livre.cover_fourth}`}
                : `https://editions7.com/admin/public/storage/${livre.cover_fourth}`}             



                    
                    alt="4ème couverture"
                    className="w-20 h-20 object-cover rounded shadow-md"
                />
            ) : (
                <p className="text-gray-500 italic">Pas de 4ème couverture</p>
            )}
            <p className="text-sm font-bold text-gray-700 mt-1">Cover 4</p>
        </div>
    </div>
</td>


                                            <td className="border border-gray-300 p-2 flex space-x-2">
                                                <button
                                                    className="bg-yellow-500 text-white px-2 py-1 rounded-lg hover:bg-yellow-600 flex items-center"
                                                    onClick={() => handleEditBook(livre)}
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600"
                                                    onClick={() => {
                                                        setBookToDelete(livre);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    Supprimer
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                           {/* Pagination uniquement si le nombre total de livres dépasse rowsPerPage */}
{livres.length > rowsPerPage && (
  <div className="flex justify-center mt-4">
    {[...Array(totalPages).keys()].map((number) => (
      <button
        key={number + 1}
        onClick={() => handlePageChange(number + 1)}
        className={`px-3 py-1 mx-1 border rounded-lg ${
          currentPage === number + 1 ? 'bg-green-500 text-white' : 'bg-gray-200'
        }`}
      >
        {number + 1}
      </button>
    ))}
  </div>
)}

                        </div>
                    </div>
                </div>
            </div>

             {/* Modal d'ajout */}
             {showModal && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 className="text-xl font-bold mb-4">Ajouter un nouveau livre</h2>
            <form
                onSubmit={(e) => {
                    handleSubmitBook(e);
                }}
            >
                {/* Nom du livre */}
                <div className="mb-4">
                    <label className="block font-bold mb-2">Nom du livre :</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 w-full"
                        value={newBook.title || ''}
                        onChange={(e) =>
                            setNewBook({ ...newBook, title: e.target.value })
                        }
                        required
                    />
                </div>

                {/* Texte de 4ème couverture */}
                <div className="mb-4">
                    <label className="block font-bold mb-2">Texte de 4ème couverture :</label>
                    <textarea
                        className="border border-gray-300 p-2 w-full"
                        value={newBook.description || ''}
                        onChange={(e) =>
                            setNewBook({ ...newBook, description: e.target.value })
                        }
                        required
                    />
                </div>

                {/* Prix */}
                <div className="mb-4">
                    <label className="block font-bold mb-2">Prix :</label>
                    <input
                        type="number"
                        className="border border-gray-300 p-2 w-full"
                        value={newBook.price || ''}
                        onChange={(e) =>
                            setNewBook({ ...newBook, price: e.target.value })
                        }
                        min="0"
                        required
                    />
                </div>

                {/* Objectif de vente */}
                <div className="mb-4">
                    <label className="block font-bold mb-2">Objectif de vente :</label>
                    <input
                        type="number"
                        className="border border-gray-300 p-2 w-full"
                        value={newBook.sales_target || ''}
                        onChange={(e) =>
                            setNewBook({ ...newBook, sales_target: e.target.value })
                        }
                        min="1"
                        required
                    />
                </div>

                {/* 1ère de couverture */}
                <div className="mb-4">
                    <label className="block font-bold mb-2">1ère de couverture :</label>
                    <input
                        type="file"
                        className="border border-gray-300 p-2 w-full"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setNewBook({ ...newBook, cover_first: file });
                        }}
                        required
                    />
                </div>

                {/* 4ème de couverture */}
                <div className="mb-4">
                    <label className="block font-bold mb-2">4ème de couverture :</label>
                    <input
                        type="file"
                        className="border border-gray-300 p-2 w-full"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setNewBook({ ...newBook, cover_fourth: file });
                        }}
                        required
                    />
                </div>
                
                {/* stock auteur */}
                <div className="mb-4">
                    <label className="block font-bold mb-2">Mon Stock:</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 w-full"
                        value={newBook.stock_auteur || ''}
                        onChange={(e) =>
                            setNewBook({ ...newBook, stock_auteur: e.target.value })
                        }
                        required
                    />
                </div>
                {/* Boutons */}
                <div className="flex justify-end space-x-4">
                    <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                        onClick={() => setShowModal(false)}
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                    >
                        Ajouter
                    </button>
                </div>
            </form>
        </div>
    </div>
)}

 {/* Success Modal */}
 
            {showSuccessModal && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-green-500 text-xl font-bold mb-4">Succès</h2>
            <p className="text-gray-700">Livre ajouté avec succès !</p>
        </div>
    </div>
)}


{showEditModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div
      className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-h-[90vh] overflow-y-auto"
    >
      <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">
        Modifier le livre
      </h2>
      <form
        onSubmit={handleSubmitEditBook}
      >
        <div className="mb-4">
          <label className="block font-bold mb-2 text-[#737373]">Nom du livre :</label>
          <input
            type="text"
            className="border border-gray-300 p-2 w-full rounded-md"
            value={selectedBook.title || ""}
            onChange={(e) =>
              setSelectedBook({ ...selectedBook, title: e.target.value })
            }
            required
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2 text-[#737373]">Texte de 4ème couverture :</label>
          <textarea
            className="border border-gray-300 p-2 w-full rounded-md"
            value={selectedBook.description || ""}
            onChange={(e) =>
              setSelectedBook({ ...selectedBook, description: e.target.value })
            }
            required
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2 text-[#737373]">Prix :</label>
          <input
            type="number"
            className="border border-gray-300 p-2 w-full rounded-md"
            value={selectedBook.price || ""}
            onChange={(e) =>
              setSelectedBook({ ...selectedBook, price: e.target.value })
            }
            required
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2 text-[#737373]">
            Objectif de vente sur 1 an :
          </label>
          <input
            type="number"
            className="border border-gray-300 p-2 w-full rounded-md"
            value={selectedBook.sales_target || ""}
            onChange={(e) =>
              setSelectedBook({ ...selectedBook, sales_target: e.target.value })
            }
            required
          />
        </div>

        {/* Champ pour la 1ère couverture */}
        <div className="mb-4">
          <label className="block font-bold mb-2 text-[#737373]">1ère de couverture :</label>
          <input
            type="file"
            className="border border-gray-300 p-2 w-full rounded-md"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              setSelectedBook((prev) => ({
                ...prev,
                cover_first: file,
              }));
            }}
          />
          {selectedBook.cover_first instanceof File && (
            <div className="mt-2">
              <p className="text-sm text-gray-500">Aperçu :</p>
              <img
                src={URL.createObjectURL(selectedBook.cover_first)}
                alt="1ère de couverture"
                className="w-32 h-32 object-cover rounded shadow-md"
              />
            </div>
          )}
        </div>

        {/* Champ pour la 4ème couverture */}
        <div className="mb-4">
          <label className="block font-bold mb-2 text-[#737373]">4ème de couverture :</label>
          <input
            type="file"
            className="border border-gray-300 p-2 w-full rounded-md"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              setSelectedBook((prev) => ({
                ...prev,
                cover_fourth: file,
              }));
            }}
          />
          {selectedBook.cover_fourth instanceof File && (
            <div className="mt-2">
              <p className="text-sm text-gray-500">Aperçu :</p>
              <img
                src={URL.createObjectURL(selectedBook.cover_fourth)}
                alt="4ème de couverture"
                className="w-32 h-32 object-cover rounded shadow-md"
              />
            </div>
          )}
        </div>

        <div className="mb-4">
          <label className="block font-bold mb-2 text-[#737373]">Mon Stock :</label>
          <input
            type="text"
            className="border border-gray-300 p-2 w-full rounded-md"
            value={selectedBook.stock_auteur || ""}
            onChange={(e) =>
              setSelectedBook({ ...selectedBook, stock_auteur: e.target.value })
            }
            required
          />
        </div>
        <div className="flex justify-end space-x-4 mt-6">
          <button
            type="button"
            className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
            onClick={handleCloseEditModal}
          >
            Annuler
          </button>
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
          >
            Modifier
          </button>
        </div>
      </form>
    </div>
  </div>
)}

{/* Modal de succès */}
{showSuccessModalEdit && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
      <h2 className="text-green-500 text-xl font-bold mb-4">Succès</h2>
      <p className="text-gray-700">
        Livre modifié avec succès !
      </p>
      <button
        onClick={() => {
          setShowSuccessModalEdit(false);
        }}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
      >
        OK
      </button>
    </div>
  </div>
)}




 {/* Delete Modal */}
 {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <h2 className="text-red-500 text-xl font-bold mb-4">Confirmer la suppression</h2>
                        <p className="text-gray-700 mb-6">
                            Êtes-vous sûr de vouloir supprimer le livre <strong>{bookToDelete?.title}</strong> ?
                        </p>
                        <div className="flex justify-end space-x-4">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                                onClick={() => setShowDeleteModal(false)}
                            >
                                Annuler
                            </button>
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                                onClick={handleDeleteBook}
                            >
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Livre;

import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Sidebar = ({ userRoles = [] }) => {
  const location = useLocation();
  const [isAuteurOpen, setIsAuteurOpen] = useState(false);
  const [showInvoicesDropdown, setShowInvoicesDropdown] = useState(false);
  const [isDiffuseurOpen, setIsDiffuseurOpen] = useState(false);


  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <ul className="space-y-4">
        {/* Fonctionnalités Globales */}
        <li className="group">
          <NavLink
            to="/compte-auteur-diffuseur"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 hover:border hover:border-green-500 hover:rounded-md px-2 py-2 flex items-center"
            }
          >
            <span className="hidden group-hover:inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Tableau de bord
          </NavLink>
        </li>

        <li className="group">
          <NavLink
            to="/informations"
            className={({ isActive }) =>
              isActive
                ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                : "font-bold text-gray-600 hover:border hover:border-green-500 hover:rounded-md px-2 py-2 flex items-center"
            }
          >
            <span className="hidden group-hover:inline-block text-green-500 mr-2">
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Mes Informations
          </NavLink>
        </li>

           <li>
                        <NavLink
                          to="/payment"
                          className={({ isActive }) =>
                            isActive
                              ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                              : "font-bold text-gray-600 hover:border hover:border-green-500 hover:rounded-md px-2 py-2 flex items-center"
                          }
                        >
                          Facture d'abonnement
                        </NavLink>
                      </li>

        {/* Dropdown pour Auteur */}
        {userRoles.includes("AUTEUR") && (
          <li className="group">
            <div
              className="font-bold text-gray-600 flex items-center cursor-pointer"
              onClick={() => setIsAuteurOpen(!isAuteurOpen)}
            >
              <IoMdArrowDropdown
                className={`h-6 w-6 transition-transform duration-200 ${
                  isAuteurOpen ? "rotate-180" : ""
                }`}
              />
              <span className="ml-2">Fonctionnalités Auteur</span>
            </div>
            {isAuteurOpen && (
              <ul className="pl-6 mt-2 space-y-2">
                {[
                  { href: "/livres-auteurs", label: "Mes livres" },
                  { href: "/recherche-pro", label: "Trouver des professionnels" },
                  { href: "/devis", label: "Devis Impression/Mes demandes Devis" },
                  { href: "/ventes", label: "Mes Ventes" },
                  { href: "/stocks", label: "Gestion de Stock" },
                ].map((item) => (
                  <li key={item.href} className="group">
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        isActive
                          ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                          : "font-bold text-gray-600 hover:border hover:border-green-500 hover:rounded-md px-2 py-2 flex items-center"
                      }
                    >
                      <span className="hidden group-hover:inline-block text-green-500 mr-2">
                        <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                      </span>
                      {item.label}
                    </NavLink>
                  </li>
                ))}

                {/* Section Mes Factures */}
                <li className="group">
                  <div
                    className="font-bold text-gray-600 flex items-center cursor-pointer"
                    onClick={() => setShowInvoicesDropdown(!showInvoicesDropdown)}
                  >
                    <IoMdArrowDropdown
                      className={`h-6 w-6 transition-transform duration-200 ${
                        showInvoicesDropdown ? "rotate-180" : ""
                      }`}
                    />
                    <span className="ml-2">Mes Factures</span>
                  </div>
                  {showInvoicesDropdown && (
                    <ul className="pl-6 mt-2 space-y-2">
                      {/* <li>
                        <NavLink
                          to="/payment"
                          className={({ isActive }) =>
                            isActive
                              ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                              : "font-bold text-gray-600 hover:border hover:border-green-500 hover:rounded-md px-2 py-2 flex items-center"
                          }
                        >
                          Facture d'abonnement
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          to="/mes-factures-impression"
                          className={({ isActive }) =>
                            isActive
                              ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                              : "font-bold text-gray-600 hover:border hover:border-green-500 hover:rounded-md px-2 py-2 flex items-center"
                          }
                        >
                          Facture d'impression de livres
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>

                {/* Section Mes Commandes */}
                <li className="group">
                  <NavLink
                    to="/commandes"
                    className={({ isActive }) =>
                      isActive
                        ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                        : "font-bold text-gray-600 hover:border hover:border-green-500 hover:rounded-md px-2 py-2 flex items-center"
                    }
                  >
                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                      <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                    </span>
                    Mes Commandes d'impression de livres
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        )}

        {/* Dropdown pour Diffuseur */}
        {userRoles.includes("DIFFUSEUR") && (
          <li className="group">
            <div
              className="font-bold text-gray-600 flex items-center cursor-pointer"
              onClick={() => setIsDiffuseurOpen(!isDiffuseurOpen)}
            >
              <IoMdArrowDropdown
                className={`h-6 w-6 transition-transform duration-200 ${
                  isDiffuseurOpen ? "rotate-180" : ""
                }`}
              />
              <span className="ml-2">Fonctionnalités Diffuseur</span>
            </div>
            {isDiffuseurOpen && (
              <ul className="pl-6 mt-2 space-y-2">
                {[
                  { href: "/points", label: "Gestion de points de vente" },
                  { href: "/recherche-auteurs", label: "Trouver des auteurs" },
                  { href: "/mes-ventes-factures", label: "Mes Ventes/Factures" },
                  { href: "/livres-diffuseur", label: "Mes livres diffusés" }, // Added item

                ].map((item) => (
                  <li key={item.href} className="group">
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        isActive
                          ? "font-bold text-white bg-green-500 rounded-md px-2 py-2 flex items-center"
                          : "font-bold text-gray-600 hover:border hover:border-green-500 hover:rounded-md px-2 py-2 flex items-center"
                      }
                    >
                      <span
                        className={`hidden group-hover:inline-block text-green-500 mr-2`}
                      >
                        <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                      </span>
                      {item.label}
                    </NavLink>
                  </li>

                  
                ))}
              </ul>
            )}
          </li>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import Header1 from '../diffuseurs/Header1';
import axios from 'axios';
import { IoMdArrowDropright } from 'react-icons/io';
import jsPDF from "jspdf";
import "jspdf-autotable";
import BASE_URL from '../../key';
import Sidebar from '../../components/sidebardiff';


const VentesAT= () => {
    const [factures, setFactures] = useState([]);
    const [factureDetails, setFactureDetails] = useState(null); // Détails de la facture
    const [isFactureModalOpen, setIsFactureModalOpen] = useState(false); // Modal de facture
    const [isFactureModalOpenComission, setIsFactureModalComissionOpen] = useState(false); // Modal de facture
    const [livresDiffuseur, setLivresDiffuseur] = useState([]);
    // const [selectedPointDeVente, setSelectedPointDeVente] = useState(null);
    // const [selectedLivre, setSelectedLivre] = useState(null);
    const [selectedPointDeVente, setSelectedPointDeVente] = useState({ id: '', libelle: '' });
const [selectedLivre, setSelectedLivre] = useState({ id: '', title: '', quantite: '', optionVente: '' });

    const [auteur, setAuteur] = useState(null);
    const [isSendModalOpen, setIsSendModalOpen] = useState(false);
    const [pointsDeVente, setPointsDeVente] = useState([]);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);



    const token = localStorage.getItem('token'); // Récupérer le token depuis le localStorage

    // Fetch Points of Sale
    const fetchPointsDeVente = async () => {
        try {
            // const response = await axios.get('http://localhost:8000/api/point', {
                const response = await axios.get(`${BASE_URL}/point`, {

                headers: { Authorization: `Bearer ${token}` },
            });
            setPointsDeVente(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des points de vente :', error);
        }
    };

    // Fetch Livres Diffuseur
    const fetchLivresDiffuseur = async () => {
        try {
            // const response = await axios.get('http://localhost:8000/api/livres-diffuseur', {
                const response = await axios.get(`${BASE_URL}/livres-diffuseur`, {

                headers: { Authorization: `Bearer ${token}` },
            });
            setLivresDiffuseur(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des livres :', error);
        }
    };

    // Open Send Modal
    const openSendModal = (point) => {
        console.log("Opening modal for point:", point); // Debugging
        setSelectedPointDeVente(point);
        setIsSendModalOpen(true);
    };
    
    

    // Close Send Modal
    const closeSendModal = () => {
        setIsSendModalOpen(false);
        setSelectedPointDeVente(null);
        setSelectedLivre(null);
    };

    // Handle Sending Book
    const handleSendBook = () => {
        // Vérifications des sélections
        if (!selectedPointDeVente || !selectedPointDeVente.id) {
            alert('Veuillez sélectionner un point de vente valide.');
            return;
        }
    
        if (!selectedLivre || !selectedLivre.id) {
            alert('Veuillez sélectionner un livre valide.');
            return;
        }
    
        if (!selectedLivre.quantite || !selectedLivre.optionVente) {
            alert('Veuillez remplir tous les champs nécessaires.');
            return;
        }
    
        // Requête pour envoyer le livre
        // fetch('http://localhost:8000/api/envoyer-livre', {
            fetch(`${BASE_URL}/envoyer-livre`, {

            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pointId: selectedPointDeVente.id,
                livreId: selectedLivre.id,
                quantite: selectedLivre.quantite,
                optionVente: selectedLivre.optionVente,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Erreur lors de l\'envoi.');
                }
                return response.json();
            })
            .then((data) => {
                console.log('Réponse du serveur :', data);
    
                const updatedPoint = data.point;
    
                // Mettre à jour les points de vente
                setPointsDeVente((prevPoints) =>
                    prevPoints.map((point) =>
                        point.id === updatedPoint.id
                            ? { ...point, facture_generee: updatedPoint.facture_generee }
                            : point
                    )
                );
    
                // Fermer le modal et afficher le succès
                closeSendModal();
                setIsSuccessModalOpen(true);
    
                setTimeout(() => {
                    setIsSuccessModalOpen(false);
                    window.location.reload();
                }, 2000);
            })
            .catch((error) => {
                console.error('Erreur lors de l\'envoi ou de la génération des factures :', error);
            });
    };
    
    useEffect(() => {
        console.log('Points de Vente récupérés :', pointsDeVente);
        console.log('Livres récupérés :', livresDiffuseur);
    }, [pointsDeVente, livresDiffuseur]);

    const handleLivreSelection = (e) => {
        const livre = livresDiffuseur.find(
            (l) => l?.id === parseInt(e.target.value)
        );
        console.log("Livre sélectionné :", livre);
        setSelectedLivre(livre || null);
    };    

    useEffect(() => {
        fetchPointsDeVente();
        fetchLivresDiffuseur();
    }, []);

    // After fetching, check the data
    useEffect(() => {
        console.log('Points de Vente:', pointsDeVente);
        console.log('Livres Diffuseur:', livresDiffuseur);
    }, [pointsDeVente, livresDiffuseur]);

    // Récupérer les factures
    const fetchFactures = async () => {
        try {
            // const response = await axios.get('http://localhost:8000/api/factures', {
                const response = await axios.get(`${BASE_URL}/factures`, {

                headers: { Authorization: `Bearer ${token}` },
            });
            setFactures(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des factures :', error);
        }
    };

    // Récupérer les détails d'une facture spécifique
    const fetchFactureDetails = async (factureId) => {
        try {
            // const response = await axios.get(`http://localhost:8000/api/facture/${factureId}`, {
                const response = await axios.get(`${BASE_URL}/facture/${factureId}`, {

                headers: { Authorization: `Bearer ${token}` },
            });
            console.log('API Response:', response.data); // Check if diffuseur exists in the response
            setFactureDetails(response.data || { facture: null, lignes: [] });
        } catch (error) {
            console.error('Erreur lors de la récupération de la facture :', error);
            setFactureDetails({ facture: null, lignes: [] });
        }
    };
    
    

    // Ouvrir le modal pour voir la facture
    const openFactureModal = async (factureId) => {
        const selectedFacture = factures.find((facture) => facture.id === factureId);
        if (selectedFacture) {
            setFactureDetails({
                ...selectedFacture, // Include the main table fields
                lignes: selectedFacture.lignes || [], // Ensure lignes exist
            });
        }
        setIsFactureModalOpen(true);

        await fetchFactureDetails(factureId); // Fetch details
        setIsFactureModalOpen(true); // Open the modal
        console.log('FactureDetails for Modal:', factureDetails); // Check if data is set
    };
    


    const openFactureComissionModal = async (factureId) => {
        try {
            // Find the selected facture from the local state
            const selectedFacture = factures.find((facture) => facture.id === factureId);
    
            // Make an API call to fetch complete details for the facture
            // const response = await axios.get(`http://localhost:8000/api/facture/${factureId}`, {
                const response = await axios.get(`${BASE_URL}/facture/${factureId}`, {

                headers: { Authorization: `Bearer ${token}` },
            });
    
            const detailedFacture = response.data;
    
            // Combine local data (if needed) with API response
            const updatedFactureDetails = {
                ...selectedFacture, // Include local facture fields
                ...detailedFacture, // Overwrite/add detailed fields from the API
            };
    
            // Set the combined data to state
            setFactureDetails(updatedFactureDetails);
    
            // Open the modal
            setIsFactureModalComissionOpen(true);
    
            console.log("Facture Details:", updatedFactureDetails); // Debugging output
        } catch (error) {
            console.error("Erreur lors de l'ouverture du modal de commission :", error);
            setFactureDetails(null); // Reset if there is an error
        }
    };
    
    useEffect(() => {
        console.log('Facture Details:', factureDetails);
    }, [factureDetails]);
        
    

    // Fermer le modal de facture
    const closeFactureModal = () => {
        setIsFactureModalOpen(false);
        setFactureDetails(null);
    };

    const closeFactureModalComission = () => {
        setIsFactureModalComissionOpen(false);
        setFactureDetails(null);
    };

    useEffect(() => {
        fetchFactures();
    }, []);


  
    const generatePDF = () => {
        if (!factureDetails) return;
      
        const doc = new jsPDF();
      
        // Titre principal
        doc.setFontSize(16);
        doc.setTextColor(40);
        // doc.text("Facture de Paiement", 14, 15);
        doc.text(`Facture N° : ${factureDetails.numero || "Non disponible"}`, 14, 15);

      
        // Adresse d'EDITIONS7 (Alignée à droite)
        const xRight = 190; // Position horizontale pour aligner parfaitement à droite
        doc.setFontSize(12);
        doc.setTextColor(0);
        doc.text("EDITIONS7", xRight, 20, { align: "right" });
        doc.text("79 PASSAGE DE LA MOUSSIERE", xRight, 25, { align: "right" });
        doc.text("69700 LOIRE SUR RHONE", xRight, 30, { align: "right" });
      
        // Informations utilisateur
        doc.setFontSize(12);
        doc.setTextColor(80);
        if (factureDetails.diffuseur) {
          doc.text(`Diffuseur : ${factureDetails.diffuseur.prenom} ${factureDetails.diffuseur.username}`, 14, 40);
          doc.text(`Adresse : ${factureDetails.diffuseur.adresse_facturation || "Non disponible"}`, 14, 45);
        } else {
          doc.text("Diffuseur : Non disponible", 14, 40);
        }
      
        // Détails de la facture (Alignés à gauche)
        doc.setFontSize(12);
        doc.setTextColor(0);
        doc.text(`Facture N° : ${factureDetails.numero || "Non disponible"}`, 14, 60);
        doc.text(
          `Date : ${
            factureDetails.date_facture
              ? new Date(factureDetails.date_facture).toLocaleDateString()
              : "Date non disponible"
          }`,
          14,
          65
        );
      
        // Tableau des lignes de la facture
        const tableColumn = ["CODE", "PRODUIT", "Remise", "QUANTITÉ", "P. Unitaire", "Prix Total"];
        const tableRows = factureDetails.lignes
          .filter((ligne) => ligne.remise > 0)
          .map((ligne) => [
            ligne.code_produit || "N/A",
            ligne.produit || "N/A",
            `${ligne.remise}%`,
            ligne.quantite || "0",
            `${ligne.prix_unitaire || "0"} €`,
            `${ligne.prix_total || "0"} €`,
          ]);
      
        doc.autoTable({
          startY: 80,
          head: [tableColumn],
          body: tableRows,
          theme: "striped",
          headStyles: { fillColor: [22, 160, 133] },
          styles: { halign: "center", fontSize: 10 },
        });
      
        // Pied de page
        const finalY = doc.lastAutoTable.finalY || 90;
        doc.setFontSize(10);
        doc.setTextColor(120);
        doc.text(
          "Editions7, 79 passage de la moussiere RCS LYON 392 354 202",
          105,
          finalY + 10,
          { align: "center" }
        );
      
        // Sauvegarde du PDF
        doc.save(`Facture-${factureDetails.numero || "Facture"}.pdf`);
      };
      
      
      
    useEffect(() => {
        console.log('Points de Vente récupérés :', pointsDeVente);
        console.log('Livres récupérés :', livresDiffuseur);
    }, [pointsDeVente, livresDiffuseur]);
    
    
    const genererPDF = () => {
        if (!factureDetails) return;
      
        const doc = new jsPDF();
      
        // Titre principal
        doc.setFontSize(16);
        doc.setTextColor(40);
        // doc.text("Facture de Paiement", 14, 15);
        doc.text(`Facture N° : ${factureDetails.numero || "Non disponible"}`, 14, 15);

      
        // Adresse d'EDITIONS7 (Alignée à droite)
        const xRight = 190; // Position horizontale pour aligner parfaitement à droite
        doc.setFontSize(12);
        doc.setTextColor(0);
        doc.text("EDITIONS7", xRight, 20, { align: "right" });
        doc.text("79 PASSAGE DE LA MOUSSIERE", xRight, 25, { align: "right" });
        doc.text("69700 LOIRE SUR RHONE", xRight, 30, { align: "right" });
      
        // Informations utilisateur (Alignées à gauche)
        doc.setFontSize(12);
        doc.setTextColor(80);
        if (factureDetails.diffuseur) {
          doc.text(
            `Diffuseur : ${factureDetails.diffuseur.prenom} ${factureDetails.diffuseur.username || ""}`,
            14,
            40
          );
          doc.text(`Adresse : ${factureDetails.diffuseur.adresse_facturation || "Non disponible"}`, 14, 45);
        } else {
          doc.text("Diffuseur : Non disponible", 14, 40);
        }
      
        // Détails de la facture (Alignés à gauche)
        doc.setFontSize(12);
        doc.setTextColor(0);
        doc.text(`Facture N° : ${factureDetails.numero || "Non disponible"}`, 14, 60);
        doc.text(
          `Date : ${
            factureDetails.date_facture
              ? new Date(factureDetails.date_facture).toLocaleDateString()
              : "Date non disponible"
          }`,
          14,
          65
        );
      
        // Tableau des lignes de la facture
        const tableColumn = ["CODE", "PRODUIT", "Comission", "QUANTITÉ", "P. Unitaire", "Prix Total"];
        const tableRows = factureDetails.lignes
          .filter((ligne) => ligne.remise == 0)
          .map((ligne) => [
            ligne.code_produit || "N/A",
            ligne.produit || "N/A",
            `${factureDetails.commission || "N/A"}%`,
            ligne.quantite || "0",
            `${ligne.prix_unitaire || "0"} €`,
            `${ligne.prix_total || "0"} €`,
          ]);
      
        doc.autoTable({
          startY: 75,
          head: [tableColumn],
          body: tableRows,
          theme: "striped",
          headStyles: { fillColor: [22, 160, 133] },
          styles: { halign: "center", fontSize: 10 },
        });
      
        // Pied de page
        const finalY = doc.lastAutoTable.finalY || 85;
        doc.setFontSize(10);
        doc.setTextColor(120);
        doc.text(
          "Editions7, 79 passage de la moussiere RCS LYON 392 354 202",
          105,
          finalY + 10,
          { align: "center" }
        );
      
        // Sauvegarde du PDF
        doc.save(`Facture-${factureDetails.numero || "Facture"}.pdf`);
      };
      
    
    
    

      
   
   
   return (
        <div className="bg-white min-h-screen">
            <Header1 />
            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                     {/* Sidebar */}
    <Sidebar />

                    <div className="md:col-span-3">
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
                            <h1 className="text-2xl font-bold mb-6">Mes Factures</h1>
                            <button
    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
    onClick={() => openSendModal(selectedPointDeVente)}
>
    Envoyer Livre
</button>

                            <table className="table-auto w-full border-collapse border border-gray-300 mt-4">
                                <thead>
                                    <tr className="bg-gray-200">
                                        {/* <th className="border border-gray-300 p-2">Facture N°</th> */}
                                        <th className="border border-gray-300 p-2">Code Produit</th>
                                        <th className="border border-gray-300 p-2">Produit</th>
                                        <th className="border border-gray-300 p-2">Quantité</th>
                                        {/* <th className="border border-gray-300 p-2">Date Facture</th> */}
                                        <th className="border border-gray-300 p-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {factures.map((facture) => (
                                        <tr key={facture.id}>
                                            {/* <td className="border border-gray-300 p-2">{facture.numero}</td> */}
                                            <td className="border border-gray-300 p-2">{facture.lignes[0]?.code_produit || "N/A"}</td>
                                            <td className="border border-gray-300 p-2">{facture.lignes[0]?.produit || "N/A"}</td>
                                            <td className="border border-gray-300 p-2">{facture.lignes[0]?.quantite || "N/A"}</td>
                                            {/* <td className="border border-gray-300 p-2">{new Date(facture.date_facture).toLocaleDateString()}</td> */}
                                            <td className="border border-gray-300 p-2">
    <div className="flex justify-center space-x-2">
        <button
            className="bg-white text-green-600 text-sm px-3 py-1 rounded-md border border-green-600 hover:bg-green-100 focus:ring-2 focus:ring-green-300 transition duration-200"
            onClick={() => openFactureModal(facture.id)}
        >
            Remise facture
        </button>
        <button
            className="bg-green-500 text-white text-sm px-3 py-1 rounded-md hover:bg-green-600 focus:ring-2 focus:ring-green-300 transition duration-200"
            onClick={() => openFactureComissionModal(facture.id)}
        >
            Comission facture
        </button>
    </div>
</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {isSendModalOpen && (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Envoyer des livres</h2>

            {/* Dropdown for Points of Sale */}
            <div className="mb-4">
    <label className="block text-sm font-semibold">Sélectionnez un point de vente</label>
    <select
    className="w-full border border-gray-300 p-2 rounded-md"
    value={selectedPointDeVente?.id || ""}
    onChange={(e) => {
        const point = pointsDeVente.find(
            (p) => p?.id === parseInt(e.target.value)
        );
        setSelectedPointDeVente(point || { id: '', libelle: '' });
    }}
>
    <option value="">-- Sélectionner un point de vente --</option>
    {pointsDeVente.map((point) => (
        <option key={point.id} value={point.id}>
            {point.libelle || "Point de Vente Inconnu"}
        </option>
    ))}
</select>




</div>


            {/* Dropdown for Books */}
            <div className="mb-4">
                <label className="block text-sm font-semibold">Sélectionnez un livre</label>
                <select
    className="w-full border border-gray-300 p-2 rounded-md"
    value={selectedLivre?.id || ""}
    onChange={(e) => {
        const livre = livresDiffuseur.find(
            (l) => l?.id === parseInt(e.target.value)
        );
        setSelectedLivre(livre || { id: '', title: '', quantite: '', optionVente: '' });
    }}
>
    <option value="">-- Sélectionner un livre --</option>
    {livresDiffuseur.map((livre) => (
        <option key={livre.id} value={livre.id}>
            {livre.title} - {livre.price} € - Par {livre.auteur?.username || 'N/A'} {livre.auteur?.prenom || ''}
        </option>
    ))}
</select>




            </div>

            {/* Display Author */}
            {auteur && (
                <p className="text-sm text-gray-600">
                    Auteur : <span className="font-semibold">{auteur}</span>
                </p>
            )}

            {/* Quantity Input */}
            <div className="mb-4">
                <label className="block text-sm font-semibold">Quantité</label>
                <input
                    type="number"
                    min="1"
                    className="w-full border border-gray-300 p-2 rounded-md"
                    onChange={(e) =>
                        setSelectedLivre((prev) => ({
                            ...prev,
                            quantite: parseInt(e.target.value),
                        }))
                    }
                />
            </div>

            {/* Option de Vente */}
            <div className="mb-4">
                <label className="block text-sm font-semibold">Option de Vente</label>
                <select
                    className="w-full border border-gray-300 p-2 rounded-md"
                    onChange={(e) =>
                        setSelectedLivre((prev) => ({
                            ...prev,
                            optionVente: e.target.value,
                        }))
                    }
                >
                    <option value="">-- Sélectionner une option --</option>
                    <option value="vente_ferme">Vente Ferme</option>
                    <option value="option_retour">Option de Retour</option>
                </select>
            </div>

            {/* Modal Buttons */}
            <div className="flex justify-end">
                <button
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
                    onClick={closeSendModal}
                >
                    Annuler
                </button>
                <button
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    onClick={handleSendBook}
                >
                    Envoyer
                </button>
            </div>
        </div>
    </div>
)}
{isSuccessModalOpen && (
  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
      <h2 className="text-xl font-bold mb-4 text-green-600">Succès</h2>
      <p className="mb-4">Le livre a été envoyé avec succès.</p>
      <button
        className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
        onClick={() => setIsSuccessModalOpen(false)}
      >
        Fermer
      </button>
    </div>
  </div>
)}
            {isFactureModalOpen && (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-lg w-4/5">
            {/* Header */}
            <div className="flex justify-between items-start mb-6">
                <div>
                    <p>
                        <strong>Diffuseur :</strong> {factureDetails.diffuseur?.prenom}{" "}
                        {factureDetails.diffuseur?.username || "(Non disponible)"}
                    </p>
                    <p>
                        <strong>Adresse :</strong> {factureDetails.diffuseur?.adresse_facturation || "Non disponible"}
                    </p>
                    {/* <p>
                        <strong>Email :</strong> {factureDetails.diffuseur?.email || "Non disponible"}
                    </p> */}
                </div>
                <div className="text-right">
                    <p>
                        <strong>EDITIONS7</strong><br />
                        79 PASSAGE DE LA MOUSSIERE<br />
                        69700 LOIRE SUR RHONE
                    </p>
                </div>
            </div>

            {/* Facture Details */}
            <div className="text-left mb-6">
                {/* <h2 className="text-xl font-bold">Facture</h2> */}
                <p>
                    <strong  className="text-xl font-bold">Facture N° :</strong> {factureDetails.numero || "Non disponible"}
                </p>
               
            </div>
            <div className="text-left mb-6">
                
                <p>
                    <strong>Date :</strong>{" "}
                    {factureDetails.date_facture
                        ? new Date(factureDetails.date_facture).toLocaleDateString()
                        : "Date non disponible"}
                </p>
            </div>
            {/* Table */}
            <table className="table-auto w-full border-collapse border border-gray-400 mt-4">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="border border-gray-300 p-2">CODE</th>
                        <th className="border border-gray-300 p-2">PRODUIT</th>
                        <th className="border border-gray-300 p-2">Remise</th>
                        <th className="border border-gray-300 p-2">Quantité</th>
                        <th className="border border-gray-300 p-2">P. Unitaire</th>
                        <th className="border border-gray-300 p-2">Prix Total</th>
                    </tr>
                </thead>
                <tbody>
                    {factureDetails.lignes?.length > 0 ? (
                        factureDetails.lignes
                            .filter((ligne) => ligne.remise > 0) // Filter rows with remise > 0
                            .slice(0, 1) // Display only the first row
                            .map((ligne, index) => (
                                <tr key={index}>
                                    <td className="border border-gray-300 p-2">{ligne.code_produit || "N/A"}</td>
                                    <td className="border border-gray-300 p-2">{ligne.produit || "N/A"}</td>
                                    <td className="border border-gray-300 p-2">{ligne.remise}%</td>
                                    <td className="border border-gray-300 p-2">{ligne.quantite || "0"}</td>
                                    <td className="border border-gray-300 p-2">{ligne.prix_unitaire || "0"} €</td>
                                    <td className="border border-gray-300 p-2">{ligne.prix_total || "0"} €</td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center text-gray-500 p-4">
                                Aucune ligne de facture disponible.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Footer */}
            <div className="text-center mt-6 text-sm text-gray-700">
                Editions7, 79 passage de la moussiere RCS LYON 392 354 202
            </div>

            {/* Buttons */}
            <div className="mt-6 flex justify-end">
                <button
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
                    onClick={closeFactureModal}
                >
                    Fermer
                </button>
                <button
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    onClick={generatePDF}
                >
                    Télécharger en PDF
                </button>
            </div>
        </div>
    </div>
)}



{isFactureModalOpenComission && (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-lg w-4/5">
            {/* Header */}
            <div className="flex justify-between items-start mb-6">
                <div>
                    <p>
                        <strong>Diffuseur :</strong> {factureDetails.diffuseur?.prenom}{" "}
                        {factureDetails.diffuseur?.username || "(Non disponible)"}
                    </p>
                    <p>
                        <strong>Adresse :</strong> {factureDetails.diffuseur?.adresse_facturation || "Non disponible"}
                    </p>
                    {/* <p>
                        <strong>Email :</strong> {factureDetails.diffuseur?.email || "Non disponible"}
                    </p> */}
                </div>
                <div className="text-right">
                    <p>
                        <strong>EDITIONS7</strong><br />
                        79 PASSAGE DE LA MOUSSIERE<br />
                        69700 LOIRE SUR RHONE
                    </p>
                </div>
            </div>

            {/* Facture Details */}
            <div className="text-left mb-6">
                {/* <h2 className="text-xl font-bold">Facture</h2> */}
                <p>
                    <strong  className="text-xl font-bold">Facture N° :</strong> {factureDetails.numero || "Non disponible"}
                </p>
               
            </div>
            <div className="text-left mb-6">
                
                <p>
                    <strong>Date :</strong>{" "}
                    {factureDetails.date_facture
                        ? new Date(factureDetails.date_facture).toLocaleDateString()
                        : "Date non disponible"}
                </p>
            </div>
            {/* Table */}
            <table className="table-auto w-full border-collapse border border-gray-400 mt-4">
    <thead>
        <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">CODE</th>
            <th className="border border-gray-300 p-2">PRODUIT</th>
            <th className="border border-gray-300 p-2">Comission</th>
            <th className="border border-gray-300 p-2">Quantité</th>
            <th className="border border-gray-300 p-2">P. Unitaire</th>
            <th className="border border-gray-300 p-2">Prix Total</th>
        </tr>
    </thead>
    <tbody>
    {factures
        .filter((facture) => facture && facture.id) // Exclude invalid factures
        .map((facture) => (
            <tr key={facture.id}>
                <td className="border border-gray-300 p-2">{facture.lignes[0]?.code_produit || "N/A"}</td>
                <td className="border border-gray-300 p-2">{facture.lignes[0]?.produit || "N/A"}</td>
                <td className="border border-gray-300 p-2">{factureDetails.commission || "N/A"}%</td>
                <td className="border border-gray-300 p-2">{facture.lignes[0]?.quantite || "N/A"}</td>

                <td className="border border-gray-300 p-2">{facture.lignes[0]?.prix_unitaire || "N/A"}</td>
                <td className="border border-gray-300 p-2">{facture.lignes[0]?.prix_total || "N/A"}</td>



              
            </tr>
        ))}
</tbody>



</table>


            {/* Footer */}
            <div className="text-center mt-6 text-sm text-gray-700">
                Editions7, 79 passage de la moussiere RCS LYON 392 354 202
            </div>

            {/* Buttons */}
            <div className="mt-6 flex justify-end">
                <button
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
                    onClick={closeFactureModalComission}
                >
                    Fermer
                </button>
                <button
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    onClick={genererPDF}
                >
                    Télécharger en PDF
                </button>
            </div>
        </div>
    </div>
)}

        </div>
    );
};

export default VentesAT;

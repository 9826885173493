import React, { useState, useEffect } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { IoMdArrowDropright } from 'react-icons/io';
import Header1 from '../diffuseurs/Header1';
import jsPDF from "jspdf";
import "jspdf-autotable";
import BASE_URL from '../../key';
import Sidebar from '../../components/sidebarAutre';

const PointAutre = () => {
  const [pointsDeVente, setPointsDeVente] = useState([]);  
  const [types, setTypes] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [newPoint, setNewPoint] = useState({
    libelle: '',
    adresse: '',
    ville: '',
    type: '',
    newType: '', 
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); 
const [editPoint, setEditPoint] = useState(null); 

const [isSendModalOpen, setIsSendModalOpen] = useState(false);
const [selectedPointDeVente, setSelectedPointDeVente] = useState(null);
const [selectedLivre, setSelectedLivre] = useState(null);
const [livres, setLivres] = useState([]); 
const [livresDiffuseur, setLivresDiffuseur] = useState([]);
const [auteur, setAuteur] = useState(null); 
const [facturesGenerees, setFacturesGenerees] = useState([]);
const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);



  
  // Get the token from localStorage
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      // If the token is not present, redirect to login
      window.location.href = '/login'; // Adjust to your login page route
      return;
    }

    // Fetch the points of sale
    // fetch('http://localhost:8000/api/point', {
      fetch(`${BASE_URL}/point`, {

        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',

        },

      })
        .then((response) => {
          console.log('Raw response:', response);
          if (!response.ok) {
            throw new Error('Not authorized or other error');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Fetched data:', data);
          setPointsDeVente(data);
        })
        .catch((error) => console.error('Erreur lors de la récupération des points de vente:', error));
      

    // Fetch the types of points of sale
    // fetch('http://localhost:8000/api/types', {
      fetch(`${BASE_URL}/types`, {

      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => response.json())
      .then((data) => setTypes(data.map(type => type.type)))  // Extract the types
      .catch((error) => console.error('Erreur lors de la récupération des types:', error));

  }, [token]); // Depend on the token
  useEffect(() => {
    // fetch('http://localhost:8000/api/livres-diffuseur', {
      fetch(`${BASE_URL}/livres-diffuseur`, {

      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des livres');
        }
        return response.json();
      })
      .then((data) => {
        setLivresDiffuseur(data);
      })
      .catch((error) => console.error('Erreur lors de la récupération des livres :', error));
  }, [token]);
  
  const openSendModal = (point) => {
    setSelectedPointDeVente(point);
    setIsSendModalOpen(true);
  };
  
  const closeSendModal = () => {
    setIsSendModalOpen(false);
    setSelectedPointDeVente(null);
    setSelectedLivre(null);
  };
    
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPoint({
      ...newPoint,
      [name]: value,
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditPoint((prevPoint) => ({
      ...prevPoint,
      [name]: value,
    }));
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
  
    // Appel à l'API pour mettre à jour le point
    // fetch(`http://localhost:8000/api/points/${editPoint.id}`, {
      fetch(`${BASE_URL}/points/${editPoint.id}`, {

      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editPoint),
    })
      .then((response) => response.json())
      .then((data) => {
        // Mettre à jour la liste localement
        setPointsDeVente((prevPoints) =>
          prevPoints.map((point) =>
            point.id === editPoint.id ? { ...point, ...editPoint } : point
          )
        );
        setIsEditModalOpen(false);
      })
      .catch((error) => console.error('Erreur lors de la modification :', error));
  };
  
  
  const handleSendBook = () => {
    if (!selectedLivre || !selectedLivre.quantite || !selectedLivre.optionVente) {
        alert('Veuillez remplir tous les champs.');
        return;
    }

    // fetch('http://localhost:8000/api/envoyer-livre', {
      fetch(`${BASE_URL}/envoyer-livre`, {

        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            pointId: selectedPointDeVente.id,
            livreId: selectedLivre.id,
            quantite: selectedLivre.quantite,
            optionVente: selectedLivre.optionVente,
        }),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Erreur lors de l\'envoi.');
            }
            return response.json();
        })
        .then((data) => {
            const updatedPoint = data.point;
            setPointsDeVente((prevPoints) =>
                prevPoints.map((point) =>
                    point.id === updatedPoint.id
                        ? { ...point, facture_generee: updatedPoint.facture_generee }
                        : point
                )
            );
            closeSendModal();
            setIsSuccessModalOpen(true); 
        })
        .catch((error) =>
            console.error('Erreur lors de l\'envoi ou de la génération des factures :', error)
        );
};

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  
  //   const requestData = {
  //     libelle: newPoint.libelle,
  //     adresse: newPoint.adresse,
  //     ville: newPoint.ville,
  //     type: newPoint.type || newPoint.newType, // Use existing or new type
  //   };
  
  //   console.log('Sending data:', requestData);
  
  //   fetch('http://localhost:8000/api/points-de-vente', {
  //     // fetch('https://editions7.com/admin/public/api/points-de-vente', {

  //     method: 'POST',
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(requestData),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         return response.json().then((errorData) => {
  //           throw new Error(`Error: ${errorData.message}`);
  //         });
  //       }
  //       return response.json();
  //     })
  //     .then((newPointDeVente) => {
  //       setPointsDeVente((prev) => [...prev, newPointDeVente]);
  
  //       if (newPoint.newType && !types.includes(newPoint.newType)) {
  //         setTypes((prev) => [...prev, newPoint.newType]);
  //       }
  
  //       setNewPoint({
  //         libelle: '',
  //         adresse: '',
  //         ville: '',
  //         type: '',
  //         newType: '',
  //       });
  //       closeModal();
  //     })
  //     .catch((error) => console.error('Erreur lors de l\'ajout du point de vente:', error));
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const requestData = {
      libelle: newPoint.libelle,
      adresse: newPoint.adresse,
      ville: newPoint.ville,
      type: newPoint.type || newPoint.newType, // Use existing or new type
    };
  
    console.log('Sending data:', requestData);
  
    // fetch('http://localhost:8000/api/points-de-vente', {
      fetch(`${BASE_URL}/points-de-vente`, {
  
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(`Error: ${errorData.message}`);
          });
        }
        return response.json();
      })
      .then((newPointDeVente) => {
        setPointsDeVente((prev) => [...prev, newPointDeVente]);
  
        if (newPoint.newType && !types.includes(newPoint.newType)) {
          setTypes((prev) => [...prev, newPoint.newType]);
        }
  
        setNewPoint({
          libelle: '',
          adresse: '',
          ville: '',
          type: '',
          newType: '',
        });
        closeModal();
  
        // Rafraîchir la page après l'enregistrement
        window.location.reload(); // Ajouté pour rafraîchir la page
      })
      .catch((error) => console.error('Erreur lors de l\'ajout du point de vente:', error));
  };
  const [isFactureModalOpen, setIsFactureModalOpen] = useState(false);
const [factureDetails, setFactureDetails] = useState(null);

const openFactureModal = (factureId) => {
    // fetch(`http://localhost:8000/api/factures/${factureId}`, {
      fetch(`${BASE_URL}/factures/${factureId}`, {

        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .then((data) => {
            setFactureDetails(data);
            setIsFactureModalOpen(true);
        })
        .catch((error) => console.error('Erreur lors du chargement de la facture:', error));
};

const closeFactureModal = () => {
    setIsFactureModalOpen(false);
    setFactureDetails(null);
};

const generatePDF = () => {
  if (!factureDetails) return;

  const doc = new jsPDF();

  // Add Title and Facture Details
  doc.setFontSize(16);
  doc.text("Facture", 14, 20);
  doc.setFontSize(12);
  doc.text(`Facture N°: ${factureDetails.facture?.id}`, 14, 30);
  doc.text(
    `Date: ${new Date(factureDetails.facture?.created_at).toLocaleDateString()}`,
    14, 
    40
  );
  doc.text(
    `Diffuseur: ${factureDetails.diffuseur?.prenom} (${factureDetails.diffuseur?.username})`,
    14,
    50
  );

  doc.text("EDITIONS7", 150, 20);
  doc.text("79 PASSAGE DE LA MOUSSIERE", 150, 30);
  doc.text("69700 LOIRE SUR RHONE", 150, 40);

  // Add Table Data
  const tableColumn = [
    "Code",
    "Produit",
    "Remise",
    "Quantité",
    "Prix Unitaire",
    "Prix Total",
  ];
  const tableRows = [
    [
      factureDetails.livre?.code || "N/A",
      factureDetails.livre?.title || "N/A",
      "35%",
      factureDetails.facture?.quantite || "N/A",
      `${factureDetails.livre?.price || "N/A"} €`,
      `${factureDetails.facture?.montant || "N/A"} €`,
    ],
  ];

  doc.autoTable({
    startY: 60,
    head: [tableColumn],
    body: tableRows,
  });

  // Save the PDF
  doc.save(`Facture-${factureDetails.facture?.id}.pdf`);
};

  

  return (
    <div className="bg-white min-h-screen">
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Sidebar */}
     {/* Sidebar */}
     <Sidebar />

          {/* Liste des points de vente */}
          <div className="col-span-3 bg-white shadow-md rounded-lg p-6">
            <div className="flex justify-between mb-4">
              <h2 className="text-2xl font-bold">Liste des points de vente</h2>
              <button
               className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                onClick={openModal}
              >
                Ajouter un point de vente
              </button>
            </div>
            <table className="table-auto border-collapse border border-gray-400 w-full">
            <thead>
            <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Points de vente</th>
                  <th className="border border-gray-300 p-2">Adresse</th>
                  <th className="border border-gray-300 p-2">Ville</th>
                  <th className="border border-gray-300 p-2">Type</th>
                  <th className="border border-gray-300 p-2">Action</th>

                </tr>
              </thead>
              <tbody>
              {pointsDeVente?.length > 0 ? (
    pointsDeVente.map((point, index) => (
        <tr key={index}>
            <td className="border border-gray-300 p-2">{point.libelle}</td>
            <td className="border border-gray-300 p-2">{point.adresse}</td>
            <td className="border border-gray-300 p-2">{point.ville}</td>
            <td className="border border-gray-300 p-2">{point.type}</td>
            <td className="border border-gray-300 p-2">
    {/* {point.facture_generee ? (
        <button
            className="bg-blue-500 text-white px-2 py-1 rounded-lg hover:bg-blue-600"
            onClick={() => openFactureModal(point.id)}
        >
            Voir la facture
        </button>
    ) : (
        <button
            className="bg-green-500 text-white px-2 py-1 rounded-lg hover:bg-green-600"
            onClick={() => openSendModal(point)}
        >
            Envoyer
        </button>
    )} */}
    {/* Modifier button */}
    <button
        className="bg-yellow-500 text-white px-2 py-1 rounded-lg hover:bg-yellow-600 ml-2"
        onClick={() => {
            setEditPoint(point); // Pré-remplir les données pour modification
            setIsEditModalOpen(true);
        }}
    >
        Modifier
    </button>
    {/* Supprimer button */}
    <button
        className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 ml-2"
        onClick={() => {
            if (window.confirm("Êtes-vous sûr de vouloir supprimer ce point de vente ?")) {
                // fetch(`http://localhost:8000/api/points/${point.id}`, {
                  fetch(`${BASE_URL}/points/${point.id}`, {

                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error("Erreur lors de la suppression.");
                        }
                        setPointsDeVente((prevPoints) =>
                            prevPoints.filter((p) => p.id !== point.id)
                        );
                    })
                    .catch((error) =>
                        console.error("Erreur lors de la suppression :", error)
                    );
            }
        }}
    >
        Supprimer
    </button>
</td>

        </tr>
    ))
) : (
    <tr>
        <td colSpan="5" className="text-center p-4">
            Aucun point de vente disponible.
        </td>
    </tr>
)}

              </tbody>
            </table>
          </div>
        </div>

        {/* Modal pour ajouter un point de vente */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-xl font-bold mb-4">Ajouter un point de vente</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-semibold">Libellé</label>
                  <input
                    type="text"
                    name="libelle"
                    value={newPoint.libelle}
                    onChange={handleInputChange}
                    className="w-full border border-gray-300 p-2 rounded-md"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-semibold">Adresse</label>
                  <input
                    type="text"
                    name="adresse"
                    value={newPoint.adresse}
                    onChange={handleInputChange}
                    className="w-full border border-gray-300 p-2 rounded-md"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-semibold">Ville</label>
                  <input
                    type="text"
                    name="ville"
                    value={newPoint.ville}
                    onChange={handleInputChange}
                    className="w-full border border-gray-300 p-2 rounded-md"
                    required
                  />
                </div>
              

                <div className="mb-4">
    <label className="block text-sm font-semibold">Type</label>
    <select
      name="type"
      value={newPoint.type}
      className="w-full border border-gray-300 p-2 rounded-md"
      onChange={handleInputChange}
      required={!newPoint.newType} // Obligatoire si newType n'est pas renseigné
    >
      <option value="">Sélectionner un type</option>
      {types.map((type, index) => (
        <option key={index} value={type}>
          {type}
        </option>
      ))}
    </select>
  </div>

  <div className="mb-4">
    <label htmlFor="newType">Ou ajouter un nouveau type</label>
    <input
      type="text"
      name="newType"
      value={newPoint.newType}
      onChange={handleInputChange}
      className="w-full border border-gray-300 p-2 rounded-md"

      placeholder="Entrer un nouveau type"
    />
  </div>


                <div className="flex justify-between">
                  <button
                    type="button"
                    className="bg-gray-500 text-white py-2 px-4 rounded"
                    onClick={closeModal}
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                    >
                    Ajouter
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

{/* Modal pour éditer un point de vente */}
{isEditModalOpen && editPoint && (
  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
      <h2 className="text-xl font-bold mb-4">Modifier un point de vente</h2>
      <form onSubmit={handleEditSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-semibold">Libellé</label>
          <input
            type="text"
            name="libelle"
            value={editPoint.libelle}
            onChange={handleEditChange}
            className="w-full border border-gray-300 p-2 rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold">Adresse</label>
          <input
            type="text"
            name="adresse"
            value={editPoint.adresse}
            onChange={handleEditChange}
            className="w-full border border-gray-300 p-2 rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold">Ville</label>
          <input
            type="text"
            name="ville"
            value={editPoint.ville}
            onChange={handleEditChange}
            className="w-full border border-gray-300 p-2 rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold">Type</label>
          <select
            name="type"
            value={editPoint.type}
            onChange={handleEditChange}
            className="w-full border border-gray-300 p-2 rounded-md"
          >
            <option value="">Sélectionner un type</option>
            {types.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => setIsEditModalOpen(false)}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
          >
            Annuler
          </button>
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
            >
            Modifier
          </button>
        </div>
      </form>
    </div>
  </div>
)}

 {/* Modal pour envoyer un livre */}
 {isSendModalOpen && selectedPointDeVente && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-xl font-bold mb-4">
                Envoyer des livres à {selectedPointDeVente.libelle}
              </h2>

              <div className="mb-4">
                <label className="block text-sm font-semibold">Sélectionnez un livre</label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-md"
                  onChange={(e) => {
                    const livre = livresDiffuseur.find(
                      (l) => l.id === parseInt(e.target.value)
                    );
                    setSelectedLivre(livre);
                    setAuteur(
                      livre && livre.auteur
                        ? `${livre.auteur.prenom} ${livre.auteur.username}`
                        : null
                    );
                  }}
                >
                  <option value="">-- Sélectionner un livre --</option>
                  {livresDiffuseur.map((livre) => (
                    <option key={livre.id} value={livre.id}>
                      {livre.title} - {livre.price} €
                    </option>
                  ))}
                </select>
              </div>

              {auteur && (
                <p className="text-sm text-gray-600">
                  Auteur : <span className="font-semibold">{auteur}</span>
                </p>
              )}

              <div className="mb-4">
                <label className="block text-sm font-semibold">Quantité</label>
                <input
                  type="number"
                  min="1"
                  className="w-full border border-gray-300 p-2 rounded-md"
                  onChange={(e) =>
                    setSelectedLivre((prev) => ({
                      ...prev,
                      quantite: parseInt(e.target.value),
                    }))
                  }
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-semibold">Option de Vente</label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-md"
                  onChange={(e) =>
                    setSelectedLivre((prev) => ({
                      ...prev,
                      optionVente: e.target.value,
                    }))
                  }
                >
                  <option value="">-- Sélectionner une option --</option>
                  <option value="vente_ferme">Vente Ferme</option>
                  <option value="option_retour">Option de Retour</option>
                </select>
              </div>

              <div className="flex justify-end">
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
                  onClick={closeSendModal}
                >
                  Annuler
                </button>
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                  onClick={handleSendBook}
                >
                  Envoyer
                </button>
              </div>
            </div>
          </div>
        )}

{isSuccessModalOpen && (
  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
      <h2 className="text-xl font-bold mb-4 text-green-600">Succès</h2>
      <p className="mb-4">Le livre a été envoyé avec succès.</p>
      <button
        className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
        onClick={() => setIsSuccessModalOpen(false)}
      >
        Fermer
      </button>
    </div>
  </div>
)}


{isFactureModalOpen && factureDetails && (
  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-4/5">

      {/* Informations sur le diffuseur */}
   
      <p>
      <p>
  <strong></strong><br />
  EDITIONS7<br />
  79 PASSAGE DE LA MOUSSIERE<br />
  69700 LOIRE SUR RHONE
</p>
{factureDetails.diffuseur && (
        <p>
          <strong>Diffuseur :</strong> {factureDetails.diffuseur.prenom} ({factureDetails.diffuseur.username})
        </p>
      )}
      </p>
      <h2 className="text-xl font-bold mb-4 text-center">
  Facture N°{factureDetails.facture?.id}
</h2>
      <p>
        <strong>Date :</strong> {new Date(factureDetails.facture?.created_at).toLocaleDateString()}
      </p>

      {/* Tableau des détails de la facture */}
      <table className="table-auto w-full border-collapse border border-gray-400 mt-4">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Code</th>
            <th className="border border-gray-300 p-2">Produit</th>
            <th className="border border-gray-300 p-2">Remise</th>
            <th className="border border-gray-300 p-2">Quantité</th>
            <th className="border border-gray-300 p-2">Prix Unitaire</th>
            <th className="border border-gray-300 p-2">Prix Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 p-2">{factureDetails.livre?.code || "N/A"}</td>
            <td className="border border-gray-300 p-2">{factureDetails.livre?.title || "N/A"}</td>
            <td className="border border-gray-300 p-2">35%</td>
            <td className="border border-gray-300 p-2">{factureDetails.facture?.quantite || "N/A"}</td>
            <td className="border border-gray-300 p-2">{factureDetails.livre?.price || "N/A"} €</td>
            <td className="border border-gray-300 p-2">{factureDetails.facture?.montant || "N/A"} €</td>
          </tr>
        </tbody>
      </table>

      {/* Boutons */}
      <div className="mt-4 flex justify-end">
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
          onClick={closeFactureModal}
        >
          Fermer
        </button>
        <button
                  className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                  onClick={generatePDF}
                >
                  Télécharger en PDF
                </button>
      </div>
    </div>
  </div>
)}

        
      </div>
    </div>
  );
};

export default PointAutre;

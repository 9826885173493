import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import Header1 from '../diffuseurs/Header1';
import BASE_URL from '../../key';
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sidbareauteurdiff";
import SidebarDiffuseur from "../../components/sidebardiff";


const LivresD = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [livre, setLivre] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false); 
  const [newStatus, setNewStatus] = useState(""); 
  const [quantiteRecue, setQuantiteRecue] = useState(0);
  const location = useLocation();
  const [isAuteurOpen, setIsAuteurOpen] = useState(false);
  const [isDiffuseurOpen, setIsDiffuseurOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const userRoles = ["AUTEUR", "DIFFUSEUR"]; 

  const [currentUserRole, setCurrentUserRole] = useState(null);


  const fetchAuthenticatedUser = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.ok) {
        const data = await response.json();
        setCurrentUserRole(data.role); // Assurez-vous que `data.role` retourne "DIFFUSEUR" ou "AUTEUR"
      } else {
        console.error("Erreur lors de la récupération des données utilisateur.");
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
    }
  };

  useEffect(() => {
    fetchAuthenticatedUser();
  }, []);

  const fetchLivreDetails = async () => {
    try {
      setLoading(true);
      // const response = await fetch(`http://localhost:8000/api/livre/${id}`, {
        const response = await fetch(`${BASE_URL}/livre/${id}`, {

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("Livre introuvable.");
        }
        throw new Error(`Erreur serveur : ${response.status}`);
      }
  
      const data = await response.json();
      setLivre(data);
  
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  useEffect(() => {
    const path = location.pathname;

    // Set dropdown open state and highlight selected item
    if (["/livres-auteurs", "/recherche-pro", "/devis" , "/ventes","stocks",].includes(path)) {
      setIsAuteurOpen(true);
      setSelectedItem(path);
    } else if (["/points", "/recherche-auteurs", "/mes-ventes-factures"].includes(path)) {
      setIsDiffuseurOpen(true);
      setSelectedItem(path);
    }
  }, [location.pathname]);


 
  
  useEffect(() => {
    fetchLivreDetails();
  }, [id]);

  const handleSaveStatus = async () => {
    if (!newStatus.trim()) {
      alert("Le statut ne peut pas être vide.");
      return;
    }

    try {
      const response = await fetch(
        // `http://localhost:8000/api/diffuseur/livres/${id}`,
        `${BASE_URL}/diffuseur/livres/${id}`,

        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ status: newStatus, quantite_recue: quantiteRecue }),
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour du statut.");
      }

      await fetchLivreDetails();
      setIsEditing(false); 
    } catch (err) {
      console.error(err.message);
    }
  };

  if (loading) {
    return <div className="text-gray-500">Chargement...</div>;
  }

  if (error) {
    return (
      <div className="text-red-500">
        <p>Erreur : {error}</p>
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => navigate("/")}
        >
          Retour à l'accueil
        </button>
      </div>
    );
  }


  return (
    <div className="bg-gray-100 min-h-screen">
      <Header1 />
      <div className="container mx-auto py-10 px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Sidebar */}
      {currentUserRole === "DIFFUSEUR" && <SidebarDiffuseur />}
       {currentUserRole === "AUTEUR-DIFFUSEUR" && <Sidebar userRoles={userRoles} />}          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
              <h1 className="text-3xl font-bold text-gray-800 mb-4">{livre.title}</h1>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Première colonne */}
                <div>
                  <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Titre de Livre :</span>{" "}
                    {livre.title || "Aucun résumé disponible."}
                  </p>
                  {/* <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Quantité :</span>{" "}
                    {livre.quantite_recue || "Aucun uanntite disponible."}
                  </p> */}
                  <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Résumé :</span>{" "}
                    {livre.resume || "Aucun résumé disponible."}
                  </p>
                  <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Pitch :</span>{" "}
                    {livre.pitch || "Aucun pitch disponible."}
                  </p>
                  <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Auteur :</span>{" "}
                    {livre.auteur?.username || "Nom d'utilisateur indisponible"}{" "}
                    {livre.auteur?.prenom || ""}
                  </p>
                </div>
                

                {/* Deuxième colonne */}
                <div>
                <div className="mb-6">
                    <span className="font-semibold text-gray-800">Prix :</span>{" "}
                    <span className="ml-2 text-gray-500">{livre.price} €</span>
                  </div>

                  <div className="mb-6">
  <span className="font-semibold text-gray-800">Quantité Envoyée :</span>{" "}
  <span className="ml-2 text-gray-500">{livre.quantite_envoyee || 0}</span>
</div>

{/* <div className="mb-6">
  <span className="font-semibold text-gray-800">Quantité Reçue :</span>{" "}
  <span className="ml-2 text-gray-500">{quantiteRecue || 0}</span>
</div> */}


                <div className="mb-6">
  <span className="font-semibold text-gray-800">Statut :</span>{" "}
  {isEditing === "status" ? (
    <div className="flex items-center space-x-2">
      <select
        className="border border-gray-300 rounded px-2 py-1 w-1/2"
        value={newStatus}
        onChange={(e) => setNewStatus(e.target.value)}
      >
        <option value="Reçu">Reçu</option>
        <option value="Rejeté">Rejeté</option>
      </select>
      <button
        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
        onClick={() => {
          handleSaveStatus("status");
          setIsEditing(null);
        }}
      >
        Modifier
      </button>
      <button
        className="bg-gray-500 text-white px-3 py-1 rounded hover:bg-gray-600"
        onClick={() => setIsEditing(null)}
      >
        Annuler
      </button>
    </div>
  ) : (
    <span
      className="ml-2 text-gray-500 cursor-pointer underline"
      onClick={() => setIsEditing("status")}
    >
      {livre.status || "Cliquer pour modifier"}
    </span>
  )}
</div>


<div className="mb-6">
  <span className="font-semibold text-gray-800"> Quantité Reçue : </span>{" "}
  {isEditing === "quantite_recue" ? (
    <div className="flex items-center space-x-2">
      <input
        type="number"
        className="border border-gray-300 rounded px-2 py-1 w-1/2"
        value={quantiteRecue}
        onChange={(e) => setQuantiteRecue(e.target.value)}
      />
      <button
        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
        onClick={() => {
          handleSaveStatus("quantite_recue");
          setIsEditing(null);
        }}
      >
        Modifier
      </button>
      <button
        className="bg-gray-500 text-white px-3 py-1 rounded hover:bg-gray-600"
        onClick={() => setIsEditing(null)}
      >
        Annuler
      </button>
    </div>
  ) : (
    <span
      className="ml-2 text-gray-500 cursor-pointer underline"
      onClick={() => setIsEditing("quantite_recue")}
    >
      {quantiteRecue || "Cliquer pour modifier"}
    </span>
  )}
</div>

                 
                  {livre.cover_first ? (
                    <img
                      // src={`http://localhost:8000/storage/${livre.cover_first.replace(
                      //   "http://localhost:8000/storage/",
                        src={`https://editions7.com/admin/public/storage/${livre.cover_first.replace(
                          "https://editions7.com/admin/public/storage/",
                    // src={`https://editions7.com/admin/public/storage/${livre.cover_first.replace(
                    //     "https://editions7.com/admin/public/storage/",
                        ""
                      )}`}
                      alt="Première couverture"
                      className="rounded-lg border border-gray-300 shadow-md object-cover w-full max-w-xs"
                    />
                  ) : (
                    <div className="bg-gray-200 rounded-lg flex items-center justify-center h-64">
                      <span className="text-gray-500">Aucune couverture disponible</span>
                    </div>
                  )}
                </div>

              </div>

              {/* Bouton retour */}
              <button
                className="mt-6 bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600"
                onClick={() => navigate(-1)} 
              >
                Retour
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivresD;


import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import Header1 from '../diffuseurs/Header1';
import BASE_URL from '../../key';
import { useLocation } from "react-router-dom";
import Sidebar from '../../components/sidbareauteurdiff';

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 46.603354, // Center of France
  lng: 1.888334,
};

const departements = [
  { code: "01", name: "Ain" },
  { code: "02", name: "Aisne" },
  { code: "03", name: "Allier" },
  { code: "04", name: "Alpes-de-Haute-Provence" },
  { code: "05", name: "Hautes-Alpes" },
  { code: "06", name: "Alpes-Maritimes" },
  { code: "07", name: "Ardèche" },
  { code: "08", name: "Ardennes" },
  { code: "09", name: "Ariège" },
  { code: "10", name: "Aube" },
  { code: "11", name: "Aude" },
  { code: "12", name: "Aveyron" },
  { code: "13", name: "Bouches-du-Rhône" },
  { code: "14", name: "Calvados" },
  { code: "15", name: "Cantal" },
  { code: "16", name: "Charente" },
  { code: "17", name: "Charente-Maritime" },
  { code: "18", name: "Cher" },
  { code: "19", name: "Corrèze" },
  { code: "2A", name: "Corse-du-Sud" },
  { code: "2B", name: "Haute-Corse" },
  { code: "21", name: "Côte-d'Or" },
  { code: "22", name: "Côtes-d'Armor" },
  { code: "23", name: "Creuse" },
  { code: "24", name: "Dordogne" },
  { code: "25", name: "Doubs" },
  { code: "26", name: "Drôme" },
  { code: "27", name: "Eure" },
  { code: "28", name: "Eure-et-Loir" },
  { code: "29", name: "Finistère" },
  { code: "30", name: "Gard" },
  { code: "31", name: "Haute-Garonne" },
  { code: "32", name: "Gers" },
  { code: "33", name: "Gironde" },
  { code: "34", name: "Hérault" },
  { code: "35", name: "Ille-et-Vilaine" },
  { code: "36", name: "Indre" },
  { code: "37", name: "Indre-et-Loire" },
  { code: "38", name: "Isère" },
  { code: "39", name: "Jura" },
  { code: "40", name: "Landes" },
  { code: "41", name: "Loir-et-Cher" },
  { code: "42", name: "Loire" },
  { code: "43", name: "Haute-Loire" },
  { code: "44", name: "Loire-Atlantique" },
  { code: "45", name: "Loiret" },
  { code: "46", name: "Lot" },
  { code: "47", name: "Lot-et-Garonne" },
  { code: "48", name: "Lozère" },
  { code: "49", name: "Maine-et-Loire" },
  { code: "50", name: "Manche" },
  { code: "51", name: "Marne" },
  { code: "52", name: "Haute-Marne" },
  { code: "53", name: "Mayenne" },
  { code: "54", name: "Meurthe-et-Moselle" },
  { code: "55", name: "Meuse" },
  { code: "56", name: "Morbihan" },
  { code: "57", name: "Moselle" },
  { code: "58", name: "Nièvre" },
  { code: "59", name: "Nord" },
  { code: "60", name: "Oise" },
  { code: "61", name: "Orne" },
  { code: "62", name: "Pas-de-Calais" },
  { code: "63", name: "Puy-de-Dôme" },
  { code: "64", name: "Pyrénées-Atlantiques" },
  { code: "65", name: "Hautes-Pyrénées" },
  { code: "66", name: "Pyrénées-Orientales" },
  { code: "67", name: "Bas-Rhin" },
  { code: "68", name: "Haut-Rhin" },
  { code: "69", name: "Rhône" },
  { code: "70", name: "Haute-Saône" },
  { code: "71", name: "Saône-et-Loire" },
  { code: "72", name: "Sarthe" },
  { code: "73", name: "Savoie" },
  { code: "74", name: "Haute-Savoie" },
  { code: "75", name: "Paris" },
  { code: "76", name: "Seine-Maritime" },
  { code: "77", name: "Seine-et-Marne" },
  { code: "78", name: "Yvelines" },
  { code: "79", name: "Deux-Sèvres" },
  { code: "80", name: "Somme" },
  { code: "81", name: "Tarn" },
  { code: "82", name: "Tarn-et-Garonne" },
  { code: "83", name: "Var" },
  { code: "84", name: "Vaucluse" },
  { code: "85", name: "Vendée" },
  { code: "86", name: "Vienne" },
  { code: "87", name: "Haute-Vienne" },
  { code: "88", name: "Vosges" },
  { code: "89", name: "Yonne" },
  { code: "90", name: "Territoire de Belfort" },
  { code: "91", name: "Essonne" },
  { code: "92", name: "Hauts-de-Seine" },
  { code: "93", name: "Seine-Saint-Denis" },
  { code: "94", name: "Val-de-Marne" },
  { code: "95", name: "Val-d'Oise" }
];



const TrouverA = () => {
  const [departement, setDepartement] = useState("");
  // const [diffuseurs, setDiffuseurs] = useState([]);
  const [auteurs, setAuteurs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDiffuseur, setSelectedDiffuseur] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(''); 
  const [livres, setLivres] = useState([]); 
  const [selectedLivre, setSelectedLivre] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [messageContent, setMessageContent] = useState("");
  const [newMessage, setNewMessage] = useState('');
  const [selectedProfession, setSelectedProfession] = useState("");
  const [loadingCities, setLoadingCities] = useState(false);
  const [cities, setCities] = useState([]); // Ajout de l'état pour stocker les villes

  const userRoles = ["AUTEUR", "DIFFUSEUR"]; 

  const getInitials = (username, prenom) => {
    const firstInitial = username ? username[0].toUpperCase() : "";
    const secondInitial = prenom ? prenom[0].toUpperCase() : "";
    return `${firstInitial}${secondInitial}`;
  };
  

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // État pour le modal de succès
  const [successMessage, setSuccessMessage] = useState(""); // Message de succès
  


  const countries = ['France', 'Belgique', 'Luxembourg', 'Suisse'];
  // const cities = [
  //   "Aix-en-Provence", "Ajaccio", "Amiens", "Angers", "Annecy", "Antibes", "Arles",
  //   "Avignon", "Bayonne", "Besançon", "Béziers", "Bordeaux", "Boulogne-Billancourt",
  //   "Brest", "Caen", "Calais", "Cannes", "Chambéry", "Charleville-Mézières",
  //   "Chartres", "Cherbourg-en-Cotentin", "Clermont-Ferrand", "Dijon", "Dunkerque",
  //   "Fort-de-France", "Grenoble", "La Rochelle", "Le Havre", "Le Mans", "Lille",
  //   "Limoges", "Lyon", "Marseille", "Metz", "Montpellier", "Mulhouse", "Nancy",
  //   "Nantes", "Nice", "Nîmes", "Orléans", "Pau", "Paris", "Perpignan", "Poitiers",
  //   "Reims", "Rennes", "Rouen", "Saint-Denis", "Saint-Étienne", "Strasbourg",
  //   "Toulon", "Toulouse", "Tours", "Valence", "Versailles", "Vannes"
  // ];
  
  console.log(cities);
  

  const fetchLivresAuteurs = async () => {
    setError('');
    try {
        // const response = await fetch('http://localhost:8000/api/livres-Auteur', {
            const response = await fetch(`${BASE_URL}/livres-Auteur`, {

            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des livres.');
        }

        const data = await response.json();
        setLivres(data);
    } catch (error) {
        setError(error.message);
    }
};

useEffect(() => {
  fetchLivresAuteurs();
}, []);

const handleModalOpen = () => {
  setIsModalOpen(true);
};

const handleModalClose = () => {
  setIsModalOpen(false);
};

const handleSubmit = (e) => {
  e.preventDefault();
  alert("Fiche de livre envoyée avec succès !");
  handleModalClose();
};


  const getCoordinatesFromAddress = async (address) => {
    const apiKey = "AIzaSyBbGmpRZdudt9VOiFSVJRTGEKjV-XG2h6o";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        console.error(`Geocoding error: ${data.status}`);
        return null;
      }
    } catch (error) {
      console.error("Geocoding API request error:", error);
      return null;
    }
  };

  
  

  // const handleSearch = async () => {
  //   setLoading(true);
  //   setError("");
  
  //   try {
  //     const token = localStorage.getItem("token");
  
  //     // Dynamically build the query string with valid parameters
  //     const params = new URLSearchParams();
  //     if (departement) params.append("departement", departement);
  //     if (selectedCountry && selectedCountry !== "") params.append("country", selectedCountry);
  //     if (selectedCity && selectedCity !== "") params.append("ville", selectedCity);
  //     if (selectedProfession && selectedProfession !== "") params.append("profession", selectedProfession);
  
  //     const response = await fetch(`${BASE_URL}/liste-auteurs?${params.toString()}`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       setError(errorData.message || "Erreur inattendue.");
  //       setAuteurs([]);
  //       return;
  //     }
  
  //     const data = await response.json();
  
  //     // Fetch coordinates for authors if missing
  //     const auteursWithCoordinates = await Promise.all(
  //       data.auteurs.map(async (auteur) => {
  //         if (!auteur.lat || !auteur.lng) {
  //           const coords = await getCoordinatesFromAddress(
  //             `${auteur.commune}, ${auteur.code_postal || ""}, ${selectedCountry || "France"}`
  //           );
  //           return {
  //             ...auteur,
  //             lat: coords?.lat || null,
  //             lng: coords?.lng || null,
  //           };
  //         }
  //         return auteur;
  //       })
  //     );
  
  //     setAuteurs(auteursWithCoordinates); // Update the authors list
  //   } catch (error) {
  //     console.error("Erreur lors de la recherche des auteurs :", error);
  //     setError("Impossible de récupérer les auteurs.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  const handleLivreChange = (e) => {
    const livreId = parseInt(e.target.value, 10);
    const selected = livres.find((livre) => livre.id === livreId);
    setSelectedLivre(selected || null);
  };


  const handleSendMessage = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    if (!newMessage.trim()) {
        alert("Le contenu du message ne peut pas être vide.");
        return;
    }

    if (!selectedDiffuseur) {
        alert("Veuillez sélectionner un destinataire.");
        return;
    }

    try {
        const token = localStorage.getItem("token"); // Auth token
        if (!token) {
            alert("Vous devez être connecté pour envoyer un message.");
            return;
        }

        // const response = await fetch('http://localhost:8000/api/messages/send', {
          const response = await fetch(`${BASE_URL}/messages/send`, {

            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                receiver_id: selectedDiffuseur.id,
                content: newMessage,
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || "Erreur lors de l'envoi du message.");
        }

        const data = await response.json();
        setSuccessMessage(`Message envoyé avec succès à ${selectedDiffuseur.username} ${selectedDiffuseur.prenom}!`);
        setNewMessage(""); // Réinitialiser le champ message
        setIsModalOpen(false); // Fermer le modal principal
        setIsSuccessModalOpen(true); // Ouvrir le modal de succès
    } catch (error) {
        console.error("Erreur lors de l'envoi du message :", error);
        alert("Erreur lors de l'envoi du message.");
    }
};


const handleSearch = async () => {
  setLoading(true);
  setError("");

  console.log("🔍 Paramètres envoyés :", {
    country: selectedCountry,
    commune: selectedCity,
    departement: departement,
    profession: selectedProfession
  });

  try {
    const token = localStorage.getItem("token");
    const params = new URLSearchParams();
    if (selectedCountry) params.append("country", selectedCountry);
    if (selectedCity) params.append("commune", selectedCity);
    if (departement) params.append("departement", departement);
    if (selectedProfession) params.append("profession", selectedProfession);

    const response = await fetch(`${BASE_URL}/liste-auteurs?${params.toString()}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      setError(errorData.message || "Erreur inattendue.");
      setAuteurs([]); // Initialisez auteurs avec un tableau vide en cas d'erreur
      return;
    }

    const data = await response.json();
    console.log("Réponse API :", data); // Pour vérifier la structure

    // Extraire la liste d'auteurs depuis `data.auteurs.data`
    const auteursArray = Array.isArray(data.auteurs?.data) ? data.auteurs.data : [];

    // Obtenez les coordonnées pour chaque auteur si manquantes
    const auteursWithCoordinates = await Promise.all(
      auteursArray.map(async (auteur) => {
        if (!auteur.lat || !auteur.lng) {
          const coords = await getCoordinatesFromAddress(
            `${auteur.commune}, ${auteur.code_postal || ""}, ${selectedCountry || "France"}`
          );
          return {
            ...auteur,
            lat: coords?.lat || null,
            lng: coords?.lng || null,
          };
        }
        return auteur;
      })
    );

    setAuteurs(auteursWithCoordinates); // Mettre à jour la liste des auteurs
  } catch (error) {
    console.error("Erreur lors de la recherche des auteurs :", error);
    setError("Impossible de récupérer les auteurs.");
  } finally {
    setLoading(false);
  }
};

 useEffect(() => {
    if (selectedCity) {
      handleSearch(); // Charger les diffuseurs lorsque la ville change
    }
  }, [selectedCity]);

  const loadCitiesFile = async (country) => {
    setLoadingCities(true);
    let filePath = '';

    if (country === 'France') {
      filePath = '/france.txt';
    } else if (country === 'Belgique') {
      filePath = '/belgique.txt';
    } else if (country === 'Suisse') {
      filePath = '/suisse.txt';
    } else if (country === 'Luxembourg') {  
      filePath = '/luxembourg.txt';
    }

    if (filePath) {
      try {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error(`Erreur lors du chargement du fichier: ${filePath}`);
        }
        const text = await response.text();
        const cityList = text.split('\n').map(city => city.trim()).filter(city => city);
        setCities(cityList);
      } catch (error) {
        console.error("Erreur lors du chargement des communes:", error);
        setCities([]);
      }
    } else {
      setCities([]);
    }
    setLoadingCities(false);
};


useEffect(() => {
  if (selectedCountry) {
    loadCitiesFile(selectedCountry);
  }
}, [selectedCountry]);


  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
         {/* Sidebar */}
         <Sidebar userRoles={userRoles} />

          {/* Main Content */}
          {/* Main Content */}
          <div className="md:col-span-3">
            <h1 className="text-2xl font-bold mb-4">Trouver des auteurs</h1>

            {/* Department selection */}
            <div className="mb-4">
  <label className="block font-bold text-[#737373] mb-2">Rechercher géographiquement :</label>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
  {/* Sélection du pays */}
  <select
    value={selectedCountry}
    onChange={(e) => setSelectedCountry(e.target.value)}
    className="border border-gray-300 p-2 rounded-md"
  >
    <option value="">Sélectionner un pays</option>
    {countries.map((country) => (
      <option key={country} value={country}>
        {country}
      </option>
    ))}
  </select>

  {/* Filtrer par département (Affiché uniquement si le pays est "France") */}
  {selectedCountry === "France" && (
    <select
      value={departement}
      onChange={(e) => setDepartement(e.target.value)}
      className="border border-gray-300 p-2 rounded-md"
    >
      <option value="">Tous les départements</option>
      {departements.map((dep) => (
        <option key={dep.code} value={dep.code}>
          {dep.code} - {dep.name}
        </option>
      ))}
    </select>
  )}

  {/* Filtrer par ville */}
  <select
    className="border border-gray-300 p-2 rounded-md"
    value={selectedCity}
    onChange={(e) => setSelectedCity(e.target.value)}
  >
    <option value="">Sélectionner une commune</option>
    {cities.map((city) => (
      <option key={city} value={city}>
        {city}
      </option>
    ))}
  </select>

  {/* Filtrer par profession */}
  <select
    value={selectedProfession}
    onChange={(e) => setSelectedProfession(e.target.value)}
    className="border border-gray-300 p-2 rounded-md"
  >
    <option value="">Toutes les professions</option>
    {[
      "AUTEUR",
      "AUTEUR-DIFFUSEUR",
     
    ].map((profession) => (
      <option key={profession} value={profession}>
        {profession}
      </option>
    ))}
  </select>

  {/* Bouton Rechercher */}
  <button
    onClick={handleSearch}
    className={`ml-4 px-4 py-2 rounded-md ${
      loading ? "bg-gray-500 cursor-not-allowed" : "bg-green-500 text-white hover:bg-green-600"
    }`}
    disabled={loading}
  >
    {loading ? "Recherche en cours..." : "Rechercher"}
  </button>
</div>

</div>


            {/* Google Map */}
            <LoadScript googleMapsApiKey="AIzaSyBbGmpRZdudt9VOiFSVJRTGEKjV-XG2h6o">
              <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={6}>
                {auteurs.map((auteur) => (
                  <Marker
                    key={auteur.id}
                    position={{ lat: parseFloat(auteur.lat), lng: parseFloat(auteur.lng) }}
                    onClick={() => setSelectedDiffuseur(auteur)}
                  />
                ))}

                {selectedDiffuseur && (
                  <InfoWindow
                    position={{
                      lat: parseFloat(selectedDiffuseur.lat),
                      lng: parseFloat(selectedDiffuseur.lng),
                    }}
                    onCloseClick={() => setSelectedDiffuseur(null)}
                  >
                    <div>
                      <h2>{selectedDiffuseur.username}</h2>
                      <p><strong>Prénom:</strong> {selectedDiffuseur.prenom}</p>
                      <p><strong>Commune:</strong> {selectedDiffuseur.commune}</p>
                      <p><strong>Téléphone:</strong> {selectedDiffuseur.telephone}</p>
                      <p><strong>Date de naissance:</strong> {selectedDiffuseur.date_naissance}</p>
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>

            {/* Diffuseurs list */}
            <h2 className="text-xl font-bold mt-8">Liste des utilisateurs</h2>
            {auteurs.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
                {auteurs.map((auteur) => (
                 <div key={auteur.id} className="border rounded-lg shadow-lg p-4 flex items-center">
                 {auteur.photo_profil ? (
                   <img
                    //  src={`http://localhost:8000/storage/${auteur.photo_profil}`}
                    src={`https://editions7.com/admin/public/storage/${auteur.photo_profil}`}

                     alt={auteur.username}
                     className="w-24 h-24 rounded-full mr-4"
                     />
                 ) : (
                  <div
        className="w-24 h-24 rounded-full mr-4 flex items-center justify-center bg-gray-300 text-white font-bold"
        style={{ fontSize: '1.5rem' }}
      >
                     {getInitials(auteur.username, auteur.prenom)}
                   </div>
                 )}
                 <div>
                   <h3 className="text-lg font-semibold mb-1">
                     {auteur.username} {auteur.prenom} ({auteur.role})
                   </h3>
                   <p className="text-gray-600 mb-2">
                     <strong>Adresse:</strong> {auteur.adresse_facturation}
                   </p>
                   <p className="text-gray-600 mb-2">
                     <strong>Code Postal:</strong> {auteur.code_postal}
                   </p>
                   <p className="text-gray-600 mb-2">
                     <strong>Né le:</strong> {auteur.date_naissance}
                   </p>
                   <button
                     className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                     onClick={() => {
                       setSelectedDiffuseur(auteur);
                       handleModalOpen();
                     }}
                   >
                     Contacter
                   </button>
                 </div>
               </div>
               
                ))}
     {isModalOpen && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
            <h2 className="text-xl font-bold mb-4">Envoyer un message</h2>
            <form onSubmit={handleSendMessage}>
                <div className="mb-4">
                    <label className="block font-bold mb-2">E-mail du destinataire :</label>
                    <input
                        type="email"
                        value={selectedDiffuseur?.email || ""}
                        readOnly
                        className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                    />
                </div>
                <div className="mb-4">
                    <label className="block font-bold mb-2">Message :</label>
                    <textarea
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        rows="4"
                        placeholder="Écrivez votre message ici..."
                    ></textarea>
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                        type="button"
                        onClick={handleModalClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                    >
                        Envoyer
                    </button>
                </div>
            </form>
        </div>
    </div>
)}

{isSuccessModalOpen && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4 text-green-500">Succès</h2>
            <p className="text-gray-700 mb-4">{successMessage}</p>
            <div className="flex justify-end">
                <button
                    onClick={() => setIsSuccessModalOpen(false)}
                    className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                >
                    Fermer
                </button>
            </div>
        </div>
    </div>
)}

 


              </div>
            ) : (
              <p className="mt-4">{loading ? "Chargement..." : "Aucun utilisateur trouvé."}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export default TrouverA;




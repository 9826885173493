import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Accueil from './pages/Acceuil';
import CommencezIci from './pages/Comencer-ici';  
import Inscription from './pages/Inscription';
import Inscription2 from './pages/inscription2';
import Connexion from './pages/connexion';
import Fonctionnement from './pages/Fonctionnement';
import Compte from './pages/Compte';
import Livre from './pages/Livre';
import Publication from './pages/Publication';
import Dashboard from './pages/Dashboard';
import TrouverDesProfessionnels from './pages/trouverdiffuseurs';
import CompteD from './pages/diffuseurs/compte';
import INFOD from './pages/diffuseurs/info';
import Facture from './pages/diffuseurs/facture';
import TrouverDesAuteurs from './pages/diffuseurs/trouverPro';
import LivreD from './pages/diffuseurs/livre';
import DevisD from './pages/diffuseurs/devis';
import LivreVentes from './pages/venteA';
import DevisAuteur from './pages/devisAuteur';
import Point from './pages/diffuseurs/point';
import Ventes from './pages/diffuseurs/vente';
import Stock from './pages/StockTable';
import Payment from './pages/factues';
import ChatApp from './pages/chat';
import LivreDetails from './pages/diffuseurs/LivreDetails';
import ConditionsGenerales from './pagesDynamique/cgv';
import RGPDPage from './pagesDynamique/rgpd';
import CompteDA from './pages/diffuseurAuteurs/daskbord';
import LivreA from './pages/diffuseurAuteurs/livre';
import TrouverDif from './pages/diffuseurAuteurs/trouverpro';
import Devis from './pages/diffuseurAuteurs/devis';
import LivreVentesA from './pages/diffuseurAuteurs/venteA';
import Stocks from './pages/diffuseurAuteurs/Stock';
import Payments from './pages/diffuseurAuteurs/paiment';
import INFODA from './pages/diffuseurAuteurs/info';
import Points from './pages/diffuseurAuteurs/points';
import TrouverA from './pages/diffuseurAuteurs/trouverAuteurs';
import VenteD from './pages/diffuseurAuteurs/venteD';
import LivresD from './pages/diffuseurAuteurs/livreDetails';
import DevisDetails from './pages/devispayement';
import FacturesTable from './pages/facturesimp';
import PaidDevisList from './pages/commande';
import UsersList from './pages/membres';
import CompteA from './pages/autres.js/dashboard';
import INFOAT from './pages/autres.js/info';
import TrouverDesAD from './pages/autres.js/trouverpro';
import PointAutre from './pages/autres.js/points';
import FactureA from './pages/autres.js/factures';
import VentesAT from './pages/autres.js/ventes';
import LivreDetailsAutre from './pages/autres.js/livreDetail';
import FacturesImp from './pages/diffuseurAuteurs/facturedevisimpression';
import DevisDetailsP from './pages/diffuseurAuteurs/devispayement';
import PaidDevisListA from './pages/diffuseurAuteurs/commande';
import LivresDiffuser from './pages/diffuseurs/livrediffuser';
import LivresDiffusers from './pages/diffuseurAuteurs/livresdiffuser';
import UsersLists from './pages/autres.js/membres';
import ChatAppdiff from './pages/chatdiff';

function App() {
  const location = useLocation();

  // const isLivreDetailsRoute = /^\/livres\/\d+$/.test(location.pathname);

  const isLivreDetailsRoute =location.pathname.startsWith("/livres/") || location.pathname.startsWith("/livre/") || location.pathname.startsWith("/devis") || location.pathname.startsWith("/devi");
  // Pages où le Header doit être caché (ajouté à la vérification dynamique pour /livres/:id)
  const hideHeaderRoutes = [
    '/mon-compte',
    '/mes-livres',
    '/mes-publications',
    '/devis',
    '/mes-informations',
    '/recherchre-diffuseurs',
    '/compte-diffuseur',
    '/information-diffuseur',
    '/factures',
    '/rechercher-auteurs',
    '/livres',
    '/devis-diffuseur',
    '/book-sales',
    '/devis-auteurs',
    '/points-ventes',
    '/mes-ventes',
    '/chat',
    '/stock',
    '/payments',
    '/compte-auteur-diffuseur',
    '/livres-auteurs',
    '/recherche-pro',
    '/devis',
    "/ventes",
    "/stocks",
    "/payment",
    "/informations",
    "/points",
    "/recherche-auteurs",
    "/mes-ventes-factures",
    "/factures-impression",
    "/mes-commandes",
    "/membres",
    "/compte-autres",
    "/informations-autres",
    "/recherche",
    "/point",
    "/facture-abonnement",
    "/vente",
    "/mes-factures-impression",
    "/commandes",
    "/livres-diffuser",
    "/livres-diffuseur",
    "/auteurs",
    "/auteur",
    
  ];

  // Cacher le Header pour les routes spécifiées ou pour la route dynamique /livres/:id
  // const shouldHideHeader = hideHeaderRoutes.includes(location.pathname) || isLivreDetailsRoute;
  const shouldHideHeader = hideHeaderRoutes.includes(location.pathname) || isLivreDetailsRoute;


  return (
    <div>
      {/* Affiche le Header uniquement si la route ne fait pas partie des routes masquées */}
      {!shouldHideHeader && <Header />}

      <Routes>
        {/* Redirection de la racine '/' vers '/Acceuil' */}
        <Route path="/" element={<Navigate to="/acceuil" />} />
        <Route path="/acceuil" element={<Accueil />} />  
        <Route path="/commencez-ici" element={<CommencezIci />} />  
        <Route path="/inscription" element={<Inscription />} />  
        <Route path="/inscriptionsuite" element={<Inscription2 />} />  
        <Route path="/login" element={<Connexion />} />  
        <Route path="/fonctionnement" element={<Fonctionnement />} /> 
        <Route path="/mes-informations" element={<Compte />} /> 
        <Route path="/mes-livres" element={<Livre />} /> 
        <Route path="/mes-publications" element={<Publication />} /> 
        <Route path="/devis" element={<Devis />} /> 
        <Route path="/mon-compte" element={<Dashboard />} /> 
        <Route path="/recherchre-diffuseurs" element={<TrouverDesProfessionnels />} /> 
        <Route path="/compte-diffuseur" element={<CompteD />} /> 
        <Route path="/information-diffuseur" element={<INFOD />} /> 
        <Route path="/factures" element={<Facture />} /> 
        <Route path="/rechercher-auteurs" element={<TrouverDesAuteurs />} /> 
        <Route path="/livres" element={<LivreD />} /> 
        <Route path="/devis-diffuseur" element={<DevisD />} /> 
        <Route path="/book-sales" element={<LivreVentes />} /> 
        <Route path="/devis-auteurs" element={<DevisAuteur />} /> 
        <Route path="/points-ventes" element={<Point />} /> 
        <Route path="/mes-ventes" element={<Ventes />} /> 
        <Route path="/chat" element={<ChatApp />} /> 
        <Route path="/stock" element={<Stock />} /> 
        <Route path="/payments" element={<Payment />} /> 
        <Route path="/conditions-generales" element={<ConditionsGenerales />} />
        <Route path="/rgpd" element={<RGPDPage />} />
        <Route path="/compte-auteur-diffuseur" element={<CompteDA />} />
        <Route path="/livres-auteurs" element={<LivreA />} />
        <Route path="/recherche-pro" element={<TrouverDif />} />
        <Route path="/devis" element={<Devis />} />
        <Route path="/ventes" element={<LivreVentesA />} />
        <Route path="/stocks" element={<Stocks />} />
        <Route path="/payment" element={<Payments />} />
        <Route path="/informations" element={<INFODA />} />
        <Route path="/points" element={<Points />} />
        <Route path="/recherche-auteurs" element={<TrouverA />} />
        <Route path="/mes-ventes-factures" element={<VenteD />} />
        <Route path="/factures-impression" element={<FacturesTable />} />
        <Route path="/mes-commandes" element={<PaidDevisList />} />
        <Route path="/membres" element={<UsersList />} />
        <Route path="/compte-autres" element={<CompteA />} />
        <Route path="/informations-autres" element={<INFOAT />} />
        <Route path="/recherche" element={<TrouverDesAD />} />
        <Route path="/point" element={<PointAutre />} />
        <Route path="/facture-abonnement" element={<FactureA />} />
        <Route path="/vente" element={<VentesAT />} />
        <Route path="/mes-factures-impression" element={<FacturesImp />} />
        <Route path="/livres-diffuser" element={<LivresDiffuser />} />
        <Route path="/livres-diffuseur" element={<LivresDiffusers />} />
        <Route path="/auteurs" element={<UsersLists />} />
        <Route path="/auteur" element={<ChatAppdiff />} />



        <Route path="/commandes" element={<PaidDevisListA />} />


























        <Route path="/livres/:id" element={<LivreDetails />} />
        <Route path="/livre/:id" element={<LivresD />} />
        <Route path="/devis/:id" element={<DevisDetails />} />
        <Route path="/devi/:id" element={<DevisDetailsP />} />



        

      </Routes>

      <Footer />
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header1 from "../diffuseurs/Header1";
import { IoMdArrowDropright } from "react-icons/io";
import BASE_URL from "../../key";
import Sidebar from "../../components/sidbareauteurdiff";

const LivresDiffusers = () => {
  const [factures, setFactures] = useState([]); // Pour stocker les factures
  const [loading, setLoading] = useState(true); // Indicateur de chargement
  const [error, setError] = useState(""); // Gestion des erreurs
  const navigate = useNavigate();
  const userRoles = ["AUTEUR", "DIFFUSEUR"]; 


  // Charger les factures depuis l'API
  useEffect(() => {
    const fetchFactures = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
          return;
        }

        const response = await fetch(`${BASE_URL}/livres-diffuser`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des factures.");
        }

        const data = await response.json();
        setFactures(data); // Mettre à jour les factures
      } catch (err) {
        console.error("Erreur :", err);
        setError(err.message || "Une erreur est survenue.");
      } finally {
        setLoading(false);
      }
    };

    fetchFactures();
  }, [navigate]);

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Sidebar */}
          <Sidebar userRoles={userRoles} />

          {/* Contenu principal */}
          <div className="md:col-span-3">
            <h1 className="text-2xl font-bold mb-4">Liste des Livres Diffusés</h1>

            {/* Indicateurs de chargement ou erreurs */}
            {loading ? (
              <p>Chargement des factures...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              // Tableau des factures
              <div className="overflow-x-auto shadow-lg sm:rounded-lg">
                <table className="min-w-full text-sm text-left text-gray-600">
                  <thead className="bg-gray-200 text-gray-800">
                    <tr>
                      <th className="px-6 py-3 text-center">Titre du Livre</th>
                      <th className="px-6 py-3 text-center">Diffuseur</th>
                      <th className="px-6 py-3 text-center">Point de Vente</th>
                      {/* <th className="px-6 py-3 text-center">Auteur</th> */}
                      <th className="px-6 py-3 text-center">Date d'envoi</th>
                      <th className="px-6 py-3 text-center">Montant Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {factures.length > 0 ? (
                      factures.map((facture) => (
                        <tr
                          key={facture.id}
                          className="border-b hover:bg-gray-100"
                        >
                          <td className="px-6 py-4 text-center">
                            {facture.titre_livre}
                          </td>
                          <td className="px-6 py-4 text-center">
                            {facture.nom_diffuseur}  {facture.prenom_diffuseur}
                          </td>
                          <td className="px-6 py-4 text-center">
                            {facture.nom_point_vente}
                          </td>
                          {/* <td className="px-6 py-4 text-center">
                            {facture.nom_auteur} {facture.prenom_auteur}

                          </td> */}
                          <td className="px-6 py-4 text-center">
                            {new Date(facture.date_facture).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 text-center">
                            {facture.montant_total} €
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="text-center py-4 text-gray-500"
                        >
                          Aucune facture disponible.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivresDiffusers;
